import React, { useEffect } from "react";
import Sidebarorg from "./Sidebar";
const MobileSidebar = ({ setMenuOpen }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div
      className="fixed flex justify-end top-[94px] right-0 bg-[#017C5780] h-full w-full xl:hidden"
      // onClick={() => setMenuOpen(false)}
    >
      <div className="bg-white h-full w-fit  text-[#191919] overflow-y-scroll  ">
        <div>
          <Sidebarorg />
        </div>
      </div>
    </div>
  );
};

export default MobileSidebar;
