import { useState, useEffect, useRef } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { CaseServices } from "../../../services/CaseServices";
import { CircularProgress } from "@mui/material";
import CustomPagination from "../../../utils/paginationUtils";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "../../../assets/editIcon.svg";
import DocumentIcon from "../../../assets/documentIcon.svg";
import DeleteIcon from "../../../assets/deleteIcon.svg";
import AddIcon from "../../../assets/addMoreIcon.svg";
import EditProcedure from "../addcases/EditProcedure";
import ManageDocuments from "./ManageDocuments";
import "./ManageProcedure.scss";
const ManageProcedure = () => {
  const [procedures, setProcedures] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isManageDocumentsOpen, setManageDocumentsOpen] = useState(false);
  let searchTimeRef = useRef();

  const handleSearch = (e) => {
    //debouncing structure
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };

  const getDefaultProceduresList = async () => {
    setIsLoading(true);
    try {
      const response = await CaseServices.getDefaultProcedure(
        searchKey,
        page,
        limit
      );
      console.log("procedure list:", response);
      setProcedures(response.data.defaultProcedures);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      if (error?.response?.message) {
        window.alert(error.response.message);
      } else {
        window.alert(error);
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getDefaultProceduresList();
  }, [searchKey, page, limit]);
  console.log("selected procedure", selectedProcedure);
  return (
    <div className="manageprocedure-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Manage Procedure
      </span>
      <div className="heading">
        Manage Procedure
        <div className="flex gap-2 items-center py-2 px-4 bg-white rounded-[8px]">
          {" "}
          <SearchIcon className="text-xl" />
          <input
            type="text"
            className="outline-none border-none"
            placeholder="Search Procedure"
            onChange={handleSearch}
          />
        </div>
        {/* <div className="btn-container"> */}
        <div
          className="text-sm text-white font-semibold bg-[#017C57] rounded-full py-2 px-4 cursor-pointer"
          onClick={() => {
            setSelectedProcedure(null);
            setIsEditOpen(true);
          }}
        >
          <img src={AddIcon} alt="add-icon" /> ADD NEW PROCEDURE
        </div>
      </div>
      <div
        style={{
          border: "1px solid #E7E7E7",
          marginBottom: "20px",
        }}
      />
      {isLoading ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className={`grid grid-cols-8  text-[#797979] text-[18px] px-8`}>
            <div>
              <div className="text-[#191919] font-semibold mb-4">
                Speciality ID
              </div>
            </div>
            <div className="col-span-2">
              <div className="text-[#191919] font-semibold mb-4">
                {" "}
                Speciality Name
              </div>{" "}
            </div>
            <div>
              <div className="text-[#191919] font-semibold mb-4">
                {" "}
                Procedure ID
              </div>{" "}
            </div>
            <div className="col-span-2">
              <div className="text-[#191919] font-semibold mb-4">
                {" "}
                Procedure Name
              </div>
            </div>
            <div>
              <div className="text-[#191919] font-semibold mb-4">
                {" "}
                Edit Procedure
              </div>
            </div>
            <div>
              <div className="text-[#191919] font-semibold mb-4">
                {" "}
                Manage Documents
              </div>
            </div>
          </div>
          {procedures?.map((procedure, idx) => {
            return (
              <div
                key={idx}
                className={`py-6 px-8 rounded-[16px] ${
                  idx % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                }`}
              >
                <div
                  className={`grid grid-cols-8  text-[#797979] text-[18px] `}
                >
                  <div>
                    <div>{procedure.specialityId}</div>
                  </div>
                  <div className="col-span-2">
                    <div>{procedure.speciality}</div>
                  </div>
                  <div>
                    <div>{procedure.procedureId}</div>
                  </div>
                  <div className="col-span-2">
                    <div>{procedure.procedure}</div>
                  </div>
                  <div>
                    <div
                      className="w-fit text-[#017C57] text-sm font-semibold  px-4 py-2 cursor-pointer"
                      onClick={() => {
                        setSelectedProcedure(procedure);
                        setIsEditOpen(true);
                      }}
                    >
                      <img
                        src={EditIcon}
                        alt="edit-icon"
                        width={20}
                        height={20}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      className=" text-[#017C57] text-sm font-semibold flex justify-between items-center cursor-pointer"
                      onClick={() => {
                        setSelectedProcedure(procedure);
                        setManageDocumentsOpen(true);
                      }}
                    >
                      <img
                        src={DocumentIcon}
                        alt="document-icon"
                        width={20}
                        height={20}
                      />
                      <div className="cursor-pointer">
                        <img src={DeleteIcon} alt="delete-icon" />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {isEditOpen &&
            (selectedProcedure ? (
              <EditProcedure
                setIsEditOpen={setIsEditOpen}
                specialityParam={selectedProcedure.speciality}
                specialityIdParam={selectedProcedure.specialityId}
                procedureParam={selectedProcedure.procedure}
                procedureIdParam={selectedProcedure.procedureId}
                compositIdParam={selectedProcedure.id}
                type="EDIT"
              />
            ) : (
              <EditProcedure setIsEditOpen={setIsEditOpen} type="ADD" />
            ))}
          {isManageDocumentsOpen && (
            <ManageDocuments
              setManageDocumentsOpen={setManageDocumentsOpen}
              specialityParam={selectedProcedure.speciality}
              specialityIdParam={selectedProcedure.specialityId}
              procedureParam={selectedProcedure.procedure}
              procedureIdParam={selectedProcedure.procedureId}
              compositIdParam={selectedProcedure.id}
            />
          )}
          <div className="flex justify-center mt-8">
            <CustomPagination
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageProcedure;
