import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Formik, Form, Field } from "formik";
import { object, string, date } from "yup";
import { CaseServices } from "../../../services/CaseServices";
import { selectedTab } from "../../../redux/roleSlice";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { logOut } from "../../../config/user";

const errorValidation = object({
  status: string().required("Required Field"),
  statustime: date().required("Required Field"),
  // biometric: string().required("Required Field"),
});

const AddDischargeForm = ({ dischargeOptions, caseId, setValue }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dischargeDetails, setDischargeDetails] = useState(null);
  const [initialValues, setInitialValues] = useState({
    status: "",
    statustime: "",
    // biometric: "",
  });

  const dispatch = useDispatch();

  const biometricOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const handleSubmit = async (values) => {
    console.log("submit called");
    const data = [
      {
        detailType: "DISCHARGE",
        caseId: caseId,
        details: {
          dischargedDate: new Date(),
          status: values.status,
          statustime: values.statustime,
          biometric: values.biometric,
        },
        status: 1,
        version: 0,
      },
    ];
    try {
      setIsSubmitting(true);
      const response = await CaseServices.addEnhancement(data);
      dispatch(selectedTab(null));
      setValue("MAP DOCUMENTS");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const getDischarge = async () => {
    try {
      const response = await CaseServices.getDischarge(caseId);
      setDischargeDetails(response?.data?.dischargeDetails?.details);
    } catch (error) {
      window.alert(error);
    }
  };

  useEffect(() => {
    getDischarge();
  }, []);

  useEffect(() => {
    if (dischargeDetails) {
      setInitialValues(dischargeDetails);
    } else {
      // Get current UTC time and convert it to local time
      const now = new Date();
      const formattedDateTime = now.toISOString().slice(0, 16); // Format for datetime-local
      setInitialValues((prevValues) => ({
        ...prevValues,
        statustime: formattedDateTime,
      }));
    }
  }, [dischargeDetails]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={errorValidation}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ errors, touched, setFieldValue, values }) => {
        const now = new Date();
        const formattedDateTime = now.toISOString().slice(0, 16); // Format for datetime-local

        return (
          <Form>
            <div className="xl:flex justify-between gap-8 mt-8">
              <div className="xl:w-1/3">
                <div className="text-sm text-[#191919] font-medium mb-4">
                  1. Please select Death/Discharge
                </div>

                <Select
                  name="status"
                  placeholder="Death/Discharge"
                  options={dischargeOptions}
                  value={dischargeOptions.filter(
                    (option) => option.value === values.status
                  )}
                  onChange={(item) => setFieldValue("status", item.value)}
                  isDisabled={values.status ? true : false} // Disable the select component
                />
                {touched.status && errors.status && (
                  <div className="field-error">{errors.status}</div>
                )}
              </div>
              <div className="xl:w-1/3 mt-4 xl:mt-0">
                <div className="text-sm text-[#191919] font-medium mb-4">
                  2. Please select the date & time for Death/Discharge
                </div>
                <Field
                  type="datetime-local"
                  name="statustime"
                  style={{ border: "1px solid #E4E4E4" }}
                  className="rounded-[8px] py-[10px] px-2 w-full"
                  // value={values.statustime}
                  // disabled={values.statustime === "" ? false : true}
                />
                {touched.statustime && errors.statustime && (
                  <div className="field-error">{errors.statustime}</div>
                )}
              </div>
              {console.log(errors)}
              {/* <div className="w-1/3">
                <div className="text-sm text-[#191919] font-medium mb-4">
                  3. Was biometric authentication taken for discharge?
                </div>
                <Select
                  name="biometric"
                  placeholder="Yes/No"
                  options={biometricOptions}
                  value={biometricOptions.filter(
                    (option) => option.value === values.biometric
                  )}
                  onChange={(item) => setFieldValue("biometric", item.value)}
                  isDisabled // Disable the select component
                />
                {touched.biometric && errors.biometric && (
                  <div className="field-error">{errors.biometric}</div>
                )}
              </div> */}
            </div>
            <div className="flex justify-center">
              {isSubmitting ? (
                <div className="flex justify-center">
                  <CircularProgress />
                </div>
              ) : (
                <button
                  type="submit"
                  style={{ border: "1px solid #017C57" }}
                  className="w-fit text-[#017C57] bg-white rounded-full mt-12 px-4 py-2 border-0 cursor-pointer"
                >
                  SAVE DETAILS
                </button>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddDischargeForm;
