import axios from "axios";
import { getAuthorizationToken } from "../config/user";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function getReferenceDocuments(caseId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/reference-documents/${caseId}`,
    headers: getAuthorizationToken(),
  });
}

function addReferenceDocuments(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/add-reference-document`,
    data: data,
    headers: getAuthorizationToken(),
  });
}
function updateReferenceDocument(data, documentId) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/update-reference-document/${documentId}`,
    data: data,
    headers: getAuthorizationToken(),
  });
}
function deleteReferenceDocument(documentId) {
  return axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/delete-reference-document/${documentId}`,
    headers: getAuthorizationToken(),
  });
}

function updateReferenceDocumentStatus(data, documentId) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/update-reference-document-status/${documentId}`,
    data: data,
    headers: getAuthorizationToken(),
  });
}
export const PreinitiateServices = {
  getReferenceDocuments,
  addReferenceDocuments,
  updateReferenceDocument,
  deleteReferenceDocument,
  updateReferenceDocumentStatus,
};
