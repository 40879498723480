import axios from "axios";
import { getAuthorizationToken } from "../config/user";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const getSubscriptionPlans = () => {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/subscription-plan`,
    headers: getAuthorizationToken(),
  });
};

export const mapSubscriptionPlan = (data) => {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/map-subscription-plan`,
    data: data,
    headers: getAuthorizationToken(),
  });
};

export const razorPayValidation = (data) => {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/razor-webhook`,
    data: data,
    headers: getAuthorizationToken(),
  });
};
