import { json } from "react-router-dom";

export const getUser = () => {
  let user = localStorage.getItem("userDetails") || "{}";
  try {
    user = JSON.parse(user);
  } catch (err) {
    console.log(err);
    user = {};
  }
  return user;
};

export const getSignUpUser = () => {
  let user = localStorage.getItem("signUpUserDetails") || "{}";
  try {
    user = JSON.parse(user);
  } catch (err) {
    console.log(err);
    user = {};
  }
  return user;
};

export const logOut = () => {
  localStorage.setItem("userDetails", {});
  localStorage.setItem("signUpUserDetails", {});
  window.location.reload();
};

export const logOut401 = () => {
  localStorage.setItem("userDetails", {});
  localStorage.setItem("signUpUserDetails", {});
};

export const getAuthorizationToken = (constentType) => {
  // console.log(getUser().session.token)
  let headers = { "content-type": "application/json" };
  headers["x-auth-token"] = getUser()?.session?.token;
  if (constentType === "form-data") {
    headers["content-type"] = "multipart/form-data";
    headers["accept"] = "application/json";
  }
  return headers;
};

export const getSignUpAuthorizationToken = (constentType) => {
  // console.log(getUser().session.token)
  let headers = { "content-type": "application/json" };
  headers["x-auth-token"] = getSignUpUser()?.session?.token;
  if (constentType === "form-data") {
    headers["content-type"] = "multipart/form-data";
    headers["accept"] = "application/json";
  }
  return headers;
};
