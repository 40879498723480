import axios from "axios";
import { getAuthorizationToken } from "../config/user";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const AddUserApi = (values) => {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/create-user`,
    headers: getAuthorizationToken(),
    data: {
      fullName: `${values.firstName} ${values.lastName}`,
      position: values.designation,
      countryCode: "+91",
      phone: values.phoneNumber,
      email: values.email,
      userName: values.username,
      password: values.password,
    },
  });
};

export const Edituserapi = (values) => {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/update-user/$id`,
    headers: getAuthorizationToken(),
    data: {
      fullName: values.firstName,
      position: values.designation,
      countryCode: "+91",
      phone: values.phoneNumber,
      email: values.email,
      password: values.password,
    },
  });
};

export const getlist = () => {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/subadmin`,
    headers: getAuthorizationToken(),
  });
};
export const updateUser = (data) => {
  return axios({
    method: "patch",
    url: `${REACT_APP_API_URL}/update-user`,
    data: data,
    headers: getAuthorizationToken(),
  });
};
