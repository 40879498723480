import React from 'react';
import pho from './Vector.png'
import './teammemberdashboard.scss'
function Teammemberdashboard() {
  return (
    <div className="App">
          <div className="profile">/ &nbsp;&nbsp;Dashboard &nbsp;&nbsp;/ &nbsp;&nbsp; ChatHistory </div>
         <img src={pho} className="pho" alt="logo" />
         <h1 className='team'>Team Member Dashboard</h1>
         <hr style={{width:"1200px", border:"1px solid rgba(228, 228, 228, 1)"}} />
    </div>
  );
}

export default Teammemberdashboard;
