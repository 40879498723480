import { useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { logOut } from "../config/user";
export default function SuggestionBoxUtil({
  searchParams,
  selectedValue,
  handleChange,
  api,
  name,
  setInputValue,
}) {
  async function loadOptions(search, loadedOptions, { page }) {
    try {
      let response;
      if (searchParams) {
        response = await api(searchParams, search, page);
      } else if (!searchParams) {
        response = await api(search, page);
      }

      if (!response || !response.data || !Array.isArray(response.data.list)) {
        throw new Error("Invalid API response");
      }

      // Sort the options alphabetically by name
      let options = [];
      if (name === "procedure") {
        options = response.data.list
          .map((li) => ({
            value: li[name],
            label: `${li["procedureId"]}-${li[name]}`, //It is done so that user can see procedureId along with name
            ...li,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
      } else {
        options = response.data.list
          .map((li) => ({ value: li[name], label: li[name], ...li }))
          .sort((a, b) => a.label.localeCompare(b.label));
      }
      let hasMore = response.data?.hasMore;
      if (options.length < 1) {
        setInputValue(search);
      } else {
        setInputValue(null);
      }

      return {
        options: options,
        hasMore: hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        console.error("Error loading options:", error);
      }
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  }

  return (
    <AsyncPaginate
      loadOptions={loadOptions}
      defaultInputValue={selectedValue}
      onChange={handleChange}
      name={name}
      additional={{
        page: 1,
      }}
      // ref={ref}
    />
  );
}
