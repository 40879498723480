import { useState, useEffect } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { NotificationServices } from "../services/NotificationServices";
import { CircularProgress } from "@mui/material";
import EditNotificationForm from "./EditNotificationForm";
import CustomPagination from "../utils/paginationUtils";
import { Switch, Stack } from "@mui/material";
import EditIcon from "../assets/editIcon.svg";
const NotificationsStructure = () => {
  const [defaultNotification, setDefaultNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selecrtedNotification, setSelectedNotification] = useState(null);
  const [openEditPopUp, setOpenEditPopUp] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 5;
  const handleStatusChange = async (values) => {
    const data = [
      {
        ...values,
        status: values?.status
          ? values.status === "ACTIVE"
            ? "INACTIVE"
            : "ACTIVE"
          : "ACTIVE",
      },
    ];
    try {
      const response = await NotificationServices.updateDefaultNotifications(
        data
      );
      console.log("status change response:", response);
      getDefaultNotificationData();
    } catch (error) {
      window.alert(error);
    }
  };
  const getDefaultNotificationData = async () => {
    setIsLoading(true);
    try {
      const response = await NotificationServices.getDefaultNotificationList(
        limit,
        page
      );
      console.log("notification data response:", response);
      setTotalPages(Math.ceil(response?.data?.total / limit));
      setDefaultNotification(response?.data?.allNotifiacations);
    } catch (error) {
      window.alert(error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getDefaultNotificationData();
  }, [page]);
  return (
    <div
      style={{
        fontFamily: "Inter",
        padding: 32,
        marginTop: 93,
        width: "100%",
      }}
    >
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Profile &nbsp; &nbsp;/ &nbsp; &nbsp; Manage Notifications
      </span>
      <div className="heading">Manage Notifications</div>
      <hr
        style={{
          color: "#E7E7E7",
        }}
      />
      {isLoading ? (
        <div className="flex h-[300px] justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          {defaultNotification &&
            defaultNotification?.map((notification, idx) => {
              return (
                <article
                  key={idx}
                  className={`${
                    idx % 2 == 0
                      ? "bg-white rounded-[16px]"
                      : "bg-[#f9f9f9] rounded-[16px]"
                  }`}
                >
                  <div className="pl-12 pr-7">
                    <div className="p-4">
                      <div className="grid grid-cols-6 justify-between items-center">
                        <div className="space-y-4">
                          <p className="text-[#191919] font-semibold">Title</p>
                          <p className="text-[#797979] text-[18px] ">
                            {notification.title ? notification.title : "--"}
                          </p>
                        </div>
                        <div className="space-y-4">
                          <p className="text-[#191919] font-semibold">From</p>
                          <p className="text-[#797979] text-[18px] ">
                            {notification.from ? notification.from : "--"}
                          </p>
                        </div>
                        <div className="space-y-4">
                          <p className="text-[#191919] font-semibold">To</p>
                          <p className="text-[#797979] text-[18px] ">
                            {notification.to ? notification.to : "--"}
                          </p>
                        </div>
                        <div className="space-y-4">
                          <p className="text-[#191919] font-semibold">Event</p>
                          <p className="text-[#797979] text-[18px] max-w-[200px]">
                            {notification.event ? notification.event : "--"}
                          </p>
                        </div>
                        <div className="space-y-4">
                          <p className="text-[#191919] font-semibold">
                            Notification Type
                          </p>

                          <p className="text-[#797979] text-[18px] ">
                            {notification.notificationType
                              ? notification.notificationType
                              : "--"}
                          </p>
                        </div>
                        <div className="space-y-4">
                          <p className="text-[#191919] font-semibold">Action</p>

                          {/* <p
                            className="text-[#797979] text-[18px] cursor-pointer"
                            onClick={() => {
                              setSelectedNotification(notification);
                              setOpenEditPopUp(true);
                            }}
                          >
                            <img src={EditIcon} alt="edit-icon" />
                          </p> */}
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <span style={{ textTransform: "capitalize" }}>
                              {notification?.status === "ACTIVE"
                                ? "Active"
                                : "Inactive"}
                            </span>
                            <Switch
                              checked={
                                notification?.status === "ACTIVE" ? true : false
                              }
                              onChange={() => {
                                handleStatusChange(notification);
                              }}
                              inputProps={{ "aria-label": "toggle status" }}
                              style={{ color: "#017C57" }}
                            />
                            <span>
                              <img
                                src={EditIcon}
                                alt="edit-icon"
                                className="cursor-pointer"
                                onClick={() => {
                                  setSelectedNotification(notification);
                                  setOpenEditPopUp(true);
                                }}
                              />
                            </span>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              );
            })}
          <div className="flex justify-center mt-8">
            <CustomPagination
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      )}

      {openEditPopUp && (
        <div>
          <EditNotificationForm
            selectedNotification={selecrtedNotification}
            setOpen={setOpenEditPopUp}
            refreshNotifications={getDefaultNotificationData}
          />
        </div>
      )}
    </div>
  );
};

export default NotificationsStructure;
