import React from "react";
import "./RevenueAnalytics.scss";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
const RevenueAnalytics = () => {
  return (
    <>
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            width: "247.67px",
            height: "242.67px",
            borderRadius: "21.33px",
            border: "1px solid rgba(228, 228, 228, 1)",
            gap: "10px",
          }}
        >
          <div
            style={{ width: "174.64px", height: "24px", marginLeft: "30px" }}
          >
            <h1
              style={{
                fontFamily: "Inter",
                fontWeight: "600px",
                lineHeight: "24px",
                fontSize: "14px",
                color: "rgba(25, 25, 25, 1)",
              }}
            >
              {" "}
              Revenue Analytics
            </h1>
          </div>
        </div>
        <div
          style={{
            width: "247.67px",
            height: "242.67px",
            borderRadius: "21.33px",
            border: "1px solid rgba(228, 228, 228, 1)",
            gap: "10px",
          }}
        >
          <div
            style={{ width: "174.64px", height: "24px", marginLeft: "30px" }}
          >
            <h1
              style={{
                fontFamily: "Inter",
                fontWeight: "600px",
                lineHeight: "24px",
                fontSize: "14px",
                color: "rgba(25, 25, 25, 1)",
              }}
            >
              Case Rejection Rate
            </h1>
            {/* <div class="blue">
              <div class="white">
                <div class="red"></div>
              </div>
            </div> */}
            <CircularProgressbarWithChildren
              value={80} // value in percentage
              strokeWidth={13}
              backgroundPadding={20}
              background={true}
              styles={{
                path: {
                  stroke: "#FFF0B4",

                  strokeLinecap: "round",
                  transformOrigin: "center center",
                },
                trail: {
                  stroke: "#017C5733",
                  transformOrigin: "center center",
                },
                backgroundColor: "#017C5733",
              }}
            >
              <CircularProgressbarWithChildren
                value={60} // value in percentage
                strokeWidth={13}
                styles={{
                  path: {
                    stroke: "#017C57",
                    strokeLinecap: "round",
                    transformOrigin: "center center",
                  },
                  trail: {
                    stroke: "#017c5733",
                    transformOrigin: "center center",
                  },
                  backgroundColor: "#017C5733",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50%",
                    fontSize: "10px",
                    color: "#017C57",
                    fontFamily: "inter, sans-serif",
                    fontWeight: 500,
                  }}
                >
                  6/10
                </div>
              </CircularProgressbarWithChildren>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            width: "247.67px",
            height: "242.67px",
            borderRadius: "21.33px",
            border: "1px solid rgba(228, 228, 228, 1)",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          <div
            style={{ width: "174.64px", height: "24px", marginLeft: "30px" }}
          >
            <h1
              style={{
                fontFamily: "Inter",
                fontWeight: "600px",
                lineHeight: "24px",
                fontSize: "14px",
                color: "rgba(25, 25, 25, 1)",
              }}
            >
              Average claim Settlemed time
            </h1>
          </div>
        </div>
        <div
          style={{
            width: "247.67px",
            height: "242.67px",
            borderRadius: "21.33px",
            border: "1px solid rgba(228, 228, 228, 1)",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          <div
            style={{ width: "174.64px", height: "24px", marginLeft: "30px" }}
          >
            <h1
              style={{
                fontFamily: "Inter",
                fontWeight: "600px",
                lineHeight: "24px",
                fontSize: "14px",
                color: "rgba(25, 25, 25, 1)",
              }}
            >
              Under settled Indicator
            </h1>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            width: "247.67px",
            height: "242.67px",
            borderRadius: "21.33px",
            border: "1px solid rgba(228, 228, 228, 1)",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          <div
            style={{ width: "174.64px", height: "24px", marginLeft: "30px" }}
          >
            <h1
              style={{
                fontFamily: "Inter",
                fontWeight: "600px",
                lineHeight: "24px",
                fontSize: "14px",
                color: "rgba(25, 25, 25, 1)",
              }}
            >
              Under settled revenue loss
            </h1>
          </div>
        </div>
        <div
          style={{
            width: "247.67px",
            height: "242.67px",
            borderRadius: "21.33px",
            border: "1px solid rgba(228, 228, 228, 1)",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          <div
            style={{ width: "174.64px", height: "24px", marginLeft: "30px" }}
          >
            <h1
              style={{
                fontFamily: "Inter",
                fontWeight: "600px",
                lineHeight: "24px",
                fontSize: "14px",
                color: "rgba(25, 25, 25, 1)",
              }}
            >
              Monthly case analytics
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default RevenueAnalytics;
