import React from 'react'
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';
import './caseDetailsForm.scss'

const CaseDetailsForm = () => {
  return (
    <form className='case-form'>
     <div className='case-form-container'>
      <label className='label'>
        Beneficiary no. 
        <input className='input-field' value={'Placeholder'} type="text" />
      </label>

      <label className='label'>
        HUID
        <input className='input-field' value={'Placeholder'}  type="text" />
      </label>
      <label className='label'>
        Hospital Name
        <input className='input-field' value={'Placeholder'}   type="text" />
      </label>
      <label className='label'>
        Hospital City
        <input className='input-field' value={'Placeholder'}  type="text" />
      </label>
      <label className='label'>
        Registered Date
        <input className='input-field' value={'Placeholder'}  type="text" />
      </label>
      <label className='label'>
        Package Cost
        <input className='input-field' value={'Placeholder'}  type="text" />
      </label>
      <label className='label'>
        Primary Diagnosis
        <input className='input-field' value={'Placeholder'}  type="text" />
      </label>
      <label className='label'>
        Doctor Name
        <input className='input-field' value={'Placeholder'}  type="text" />
      </label>
      <label className='label'>
        ICHI Code/Description
        <input className='input-field' value={'Placeholder'}  type="text" />
      </label>
      </div>
      <hr style={{color:'#E4E4E4', marginTop:'40px', width:'100%'}} />
      <div className='case-form-container1'>
      <label className='label1'>
        <div className='text-content'>
        Speciality 
        {/* <SaveAsOutlinedIcon sx={{width:'16px', color:'#017C57'}}/>  */}
        </div>
        <input className='input-field' value={'Placeholder'} type="text" />
      </label>
      <label className='label1'>
        <div className='text-content'>
        Procedure Id & Name 
        {/* <SaveAsOutlinedIcon sx={{width:'16px', color:'#017C57'}}/>  */}
        </div>
        <input className='input-field' value={'Placeholder'} type="text" />
      </label>
      <label className='label1'>
        <div className='text-content'>
        Stratification 
        {/* <SaveAsOutlinedIcon sx={{width:'16px', color:'#017C57'}}/>  */}
        </div>
        <input className='input-field' value={'Placeholder'} type="text" />
      </label>
      
      <label className='label1'>
        <div className='text-content'>
        Admission Type 
        {/* <SaveAsOutlinedIcon sx={{width:'16px', color:'#017C57'}}/>  */}
        </div>
        <input className='input-field' value={'Placeholder'} type="text" />
      </label>
      <label className='label1'>
        <div className='text-content'>
        Placeholder
        {/* <SaveAsOutlinedIcon sx={{width:'16px', color:'#017C57'}}/>  */}
        </div>
        <input className='input-field' value={'Placeholder'} type="text" />
      </label>
      </div>
      <div className='button-box'>
        <button className='fill-btn'> <ReportProblemOutlinedIcon sx={{height:'16px'}}/>ALERT HSA</button>
        <button className='outline-btn'> <WifiCalling3OutlinedIcon sx={{height:'16px'}}/>CALL HSA</button>
        
      </div>
    </form>
  )
}

export default CaseDetailsForm