import { useState, useEffect } from "react";
import { CaseServices } from "../../../services/CaseServices";
import DocumentIcon from "../../../assets/documentIcon.svg";
import DownloadIcon from "../../../assets/downloadIcon.svg";
import { AuthServices } from "../../../services/AuthServices";
import JsPDF from "jspdf";
import { logOut } from "../../../config/user";
import { CircularProgress } from "@mui/material";
const DownloadDocuments = ({ caseId }) => {
  const [mappedDocuments, setMappedDocuments] = useState(null);
  const [documentDownloading, setDocumentDownloading] = useState(null);
  const [documentSubDownloading, setDocumentSubDownloading] = useState(null);
  const generatePDF = async (urlData, documentName) => {
    const imagesUrl = urlData.map((url) => url.documentUrl);
    if (imagesUrl.length > 0) {
      try {
        let response = await AuthServices.loadImagesBase64({
          images: imagesUrl,
        });
        const report = new JsPDF("portrait", "pt", "a4");
        const margin = 0;
        const imgWidth = 595;
        const imgHeight = 845;

        let yPosition = 0;
        if (response?.data) {
          response.data.forEach((img, idx) => {
            if (idx !== 0) {
              report.addPage();
            }
            report.addImage(
              img,
              "JPEG",
              yPosition,
              margin,
              imgWidth,
              imgHeight
            );

            // yPosition += imgHeight + margin; // Update Y position for the next image
          });
        }
        report.save(`${documentName}`);
        // setPdfCount(pdfCount + 1);
      } catch (error) {
        window.alert(error);
      }
    } else {
      window.alert("No Document Uploaded Yet");
    }

    setDocumentDownloading(null);
    setDocumentSubDownloading(null);
  };
  const handleMultipleDownloads = (documentsData) => {
    for (let doc of documentsData) {
      generatePDF(doc?.documenturlmasters, doc?.documentName);
    }
  };

  const getMappedDocuments = async () => {
    try {
      const response = await CaseServices.getMappedDocuments(caseId);
      console.log("response:", response.data.mappedDocuments);
      let data = [];

      for (let docType in response.data.mappedDocuments) {
        data.push({
          key: docType,
          value: response.data.mappedDocuments[docType],
        });
      }
      setMappedDocuments(data);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  useEffect(() => {
    getMappedDocuments();
  }, []);
  return (
    <div>
      {mappedDocuments?.map((document, idx) => {
        return (
          <div key={idx} className="mt-8">
            <div className="flex justify-between mb-2">
              <div className="text-xl font-semibold">{document.key}</div>
              {document?.value.length > 0 &&
                (documentDownloading === idx ? (
                  <CircularProgress />
                ) : (
                  <div
                    className="flex gap-1 items-center text-white text-sm bg-[#017C57] rounded-full py-2 px-4 cursor-pointer"
                    onClick={() => {
                      setDocumentDownloading(idx);
                      handleMultipleDownloads(document?.value);
                    }}
                  >
                    <img src={DownloadIcon} alt="download-icon" />
                    {`All ${document.key}`}
                  </div>
                ))}
            </div>

            <div
              style={{ borderBottom: "1px solid #E4E4E4", marginBlock: "20px" }}
            />
            {document?.value.length > 0 ? (
              <div className="grid grid-cols-4 gap-5">
                {document?.value?.map((doc, index) => {
                  return (
                    <div
                      key={index}
                      style={{ border: "1.5px solid #E4E4E4" }}
                      className="h-[170px] flex flex-col justify-between rounded-[16px] p-6 "
                    >
                      <div className="text-xl text-[#191919] font-semibold font gap-2">
                        <img src={DocumentIcon} alt="document-icon" />{" "}
                        {doc.documentName}
                      </div>
                      {documentDownloading === idx ||
                      documentSubDownloading === doc.id ? (
                        <CircularProgress />
                      ) : (
                        <div
                          className="w-fit flex items-center gap-1 text-sm text-white font-semibold bg-[#017C57] rounded-full cursor-pointer px-4 py-2"
                          onClick={() => {
                            setDocumentSubDownloading(doc?.id);
                            generatePDF(
                              doc?.documenturlmasters,
                              doc?.documentName
                            );
                          }}
                        >
                          <img src={DownloadIcon} alt="download-icon" />
                          Download
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="text-center text-xl font-semibold pb-6">
                No Documents Mapped
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DownloadDocuments;
