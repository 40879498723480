import React from "react";
import "./CasewiseDashboard.scss";
import Arrow from "../assets/Arrow.png";
import { getUser } from "../config/user";

function Hospitaldashboard() {
  const data = [
    { name: "John de", time: "2h" },
    { name: "John de", time: "2h" },
    { name: "John de", time: "2h" },
    { name: "John de", time: "2h" },
  ];

  const user = getUser();
  console.log("userDetails:", user);
  return (
    <div className="dashboard-container">
      <div className="team">
        <span className="team-status">Team and case wise status</span>

        <select className="dropdown">
          <option>This Month</option>
        </select>
      </div>
      <hr
        style={{
          marginBlock: "24px",
          color: "rgba(228, 228, 228, 1)",
        }}
      />
      <div className="custom-box">
        <div className="average-container">
          <span className="response-time">Average Response Time</span>
          <span className="two-hours">2h</span>
        </div>

        <div className="member-container">
          <span className="member">Member</span>
          <span className="response">Response time</span>
        </div>

        {data.map((item, index) => (
          <div className="name" key={index}>
            <span className="first">{item.name}</span>
            <span className="hours">{item.time}</span>
          </div>
        ))}
      </div>
      <div className="viewmore">
        <span className="button">VIEW MORE</span>
        <div>
          <img src={Arrow} className="arrow" alt="Frame" />
        </div>
      </div>
    </div>
  );
}

export default Hospitaldashboard;
