import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import CaseListV2 from "./caseListV2";
import { useDispatch } from "react-redux";
import { setOutsideUser } from "../../redux/roleSlice";
import { useNavigate } from "react-router-dom";
import "./manageCases.scss";

const ActiveCasesDashboardV2 = () => {
  const [caseStatus, setCaseStatus] = useState("");
  const [isAddingCase, setIsAddingCase] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = searchParams.get("caseId");
  const token = searchParams.get("token");
  if (token) {
    dispatch(setOutsideUser(true));
    const userDetails = JSON.stringify({
      session: {
        token: atob(decodeURIComponent(token)),
        updatedAt: "2024-09-12T11:40:46.539Z",
        createdAt: "2024-09-12T11:40:46.539Z",
      },
      hospital: {},
    });
    localStorage.setItem("userDetails", userDetails);
  }

  const handleAddNewCase = () => {
    navigate("/addcases");
  };

  const handleSaveCase = (newCase) => {
    // Logic to save the new case
    console.log("New case added:", newCase);
    setIsAddingCase(false);
  };
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <div className="manage-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Manage Cases
      </span>
      <div
        style={{
          display: width > 640 ? "flex" : "block",
        }}
        className="heading"
      >
        Manage Cases V2
        <div className="btn-container">
          <button
            className="outlined-btn"
            style={caseStatus === "ACTIVE" ? {} : { background: "none" }}
            onClick={() => setCaseStatus("ACTIVE")}
          >
            ACTIVE CASE
          </button>
          <button
            className="outlined-btn"
            style={caseStatus === "INACTIVE" ? {} : { background: "none" }}
            onClick={() => setCaseStatus("INACTIVE")}
          >
            INACTIVE CASE
          </button>
          <button
            className="outlined-btn"
            style={caseStatus === "INACTIVE" ? {} : { background: "none" }}
            // onClick={() => setCaseStatus("INACTIVE")}
          >
            DELETE
          </button>
          <button
            style={{ cursor: "pointer" }}
            className="filled-btn"
            onClick={handleAddNewCase}
          >
            + ADD CASE
          </button>
        </div>
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          marginBottom: "20px",
        }}
      />
      {isAddingCase ? (
        <div className="add-case-form">
          {/* Replace this with your form implementation */}
          <h3>Add New Case</h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const newCase = {}; // Populate with form data
              handleSaveCase(newCase);
            }}
          >
            {/* Add form fields here */}
            <button type="submit">Save Case</button>
            <button type="button" onClick={() => setIsAddingCase(false)}>
              Cancel
            </button>
          </form>
        </div>
      ) : (
        <>
          {id ? (
            <CaseListV2
              caseStatus={caseStatus}
              setCaseStatus={setCaseStatus}
              caseId={atob(decodeURIComponent(id))}
            />
          ) : (
            <CaseListV2 caseStatus={caseStatus} setCaseStatus={setCaseStatus} />
          )}
        </>
      )}
    </div>
  );
};

export default ActiveCasesDashboardV2;
