import React, { useState } from "react";
import { Paper } from "@mui/material";
import { BarChart } from "../utils/chartUtils";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { ChartLabel } from "../utils/chartUtils";
import "./teamWiseStatus.scss";

const TeamWiseStatus = () => {
  const [teamCaseList, setTeamCaseList] = useState([1, 2, 3, 4, 5]);
  return (
    <Paper className="paper">
      <div className="heading">
        Team and Case wise status{" "}
        <select className="outlined-btn">
          <option value=""> THIS MONTH</option>
        </select>
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          margin: "0px 16px 24px 16px",
        }}
      />
      <div className="chart-container">
        <div>
          <ChartLabel label="Active Cases" color="#CDFAF4" />
          <ChartLabel label="Pending Cases" color="#FFF0B4" />
        </div>
        <div style={{ display: "flex" }}>
          <BarChart
            chartData={{
              labels: ["Me", "My"],
              datasets: [
                {
                  label: "Users Gained ",
                  data: [999, 400],
                  backgroundColor: ["#cdfaf4", "#FFF0B4"],
                  borderColor: "white",
                  borderWidth: 1,
                  borderRadius: 4,
                  borderAlign: "inner",
                },
              ],
            }}
            options={{
              scales: {
                x: {
                  barThickness: "2",
                },
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
          <BarChart
            chartData={{
              labels: ["Me", "My"],
              datasets: [
                {
                  label: "Users Gained ",
                  data: [999, 400],
                  backgroundColor: ["#cdfaf4", "#FFF0B4"],
                  borderColor: "white",
                  borderWidth: 1,
                  borderRadius: 4,
                  borderAlign: "inner",
                },
              ],
            }}
            options={{
              scales: {
                x: {
                  barThickness: "2",
                },
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
          <BarChart
            chartData={{
              labels: ["Me", "My"],
              datasets: [
                {
                  label: "Users Gained ",
                  data: [999, 400],
                  backgroundColor: ["#cdfaf4", "#FFF0B4"],
                  borderColor: "white",
                  borderWidth: 1,
                  borderRadius: 4,
                  borderAlign: "inner",
                },
              ],
            }}
            options={{
              scales: {
                x: {
                  barThickness: "2",
                },
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
          <BarChart
            chartData={{
              labels: ["Me", "My"],
              datasets: [
                {
                  label: "Users Gained ",
                  data: [999, 400],
                  backgroundColor: ["#cdfaf4", "#FFF0B4"],
                  borderColor: "white",
                  borderWidth: 1,
                  borderRadius: 4,
                  borderAlign: "inner",
                },
              ],
            }}
            options={{
              scales: {
                x: {
                  barThickness: "2",
                },
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </div>
      </div>
      <div className="heading" style={{ fontSize: "16px" }}>
        Teams
        <button className="outlined-btn" style={{ width: "119px" }}>
          EXPAND <OpenInNewOutlinedIcon fontSize="small" />
        </button>
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          margin: "0px 16px 24px 16px",
        }}
      />
       {teamCaseList.map((cases, idx) => {
        return (
          <div key={idx} className="case-list">
            <div className="left-column">
              <div style={{ fontWeight: 600 }}>Team B</div>
              <FiberManualRecordRoundedIcon className="dot" />
              <div>Manager Name</div>
            </div>
            <div className="right-column">
              <div>Response : 2h</div>
              <KeyboardArrowDownRoundedIcon className="drop-down" />
            </div>
          </div>
        );
      })}
    </Paper>
  );
};

export default TeamWiseStatus;
