import * as React from "react";
import Box from "@mui/material/Box";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Modal from "@mui/material/Modal";
import { AuthServices } from "../services/AuthServices";
import { logOut } from "../config/user";
import CloseIcon from "../assets/crossIcon.svg";
import "./uploadImage.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "720px",
  height: "560px",
  borderRadius: "16px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export default function UploadImage({ setFieldValue }) {
  const [open, setOpen] = React.useState(false);
  const [docUrl, setDocUrl] = React.useState("");
  const [uploadstatus, setuploadstatus] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const uploadImage = async (e) => {
    if (e.target.files[0].size > 5242880) {
      window.alert("Please upload a file smaller than 2 MB");
    } else {
      try {
        let data = new FormData();
        data.append("image", e.target.files[0]);
        let res = await AuthServices.uploadImage(data);
        setuploadstatus(true);
        // setUploadedImage(uploadedImageUrl);
        window.alert("Image uploaded successfully");
        setDocUrl(res?.data?.url);
        setFieldValue("businessDocumentUrl", res?.data?.url);
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else {
          window.alert(error);
        }
      }
    }
  };

  return (
    <div className="upload-modal-container">
      <button type="button" className="open-btn" onClick={handleOpen}>
        <FileUploadOutlinedIcon fontSize="small" /> UPLOAD
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="custom-modal"
      >
        <Box sx={style}>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              fontFamily: "Inter, sans-serif",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div
              className="absolute top-0 right-0 cursor-pointer"
              onClick={handleClose}
            >
              <img src={CloseIcon} alt="close-icon" />
            </div>
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "24px",
                fontWeight: "600",
                color: "#191919",
              }}
            >
              Upload
              {/* {documentDetails?.documentName} */}
            </div>
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "16px",
                fontWeight: "400",
                color: "#797979",
                marginTop: "16px",
              }}
            >
              {/* {documentDetails?.description}  */}
              document description
            </div>
            <button
              style={{
                height: "34px",
                width: "118px",
                borderRadius: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Inter, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                backgroundColor: "#017c57",
                color: "white",
                border: "none",
                gap: "8px",
                marginTop: "64px",
              }}
              onClick={() => handleButtonClick()}
            >
              <FileUploadOutlinedIcon fontSize="small" /> UPLOAD
            </button>
            <input
              type="file"
              onChange={(e) => uploadImage(e)}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "12px",
                fontWeight: "400",
                color: "#797979",
                marginTop: "4px",
              }}
            >
              Files Supported: .pdf .png .jpg
            </div>
            {uploadstatus && (
              <img
                src={docUrl}
                alt="Document"
                style={{ width: "50%", height: "50%" }}
              />
            )}

            {/* <Image source={{u ri: uploadedImage }} style={{ width: 200, height: 200 }} /> */}
            <button
              type="button"
              style={{
                height: "34px",
                width: "160px",
                borderRadius: "32px",
                marginTop: "64px",
                fontFamily: "Inter, sans-serif",
                textAlign: "center",
                fontWeight: "600",
                fontSize: "12px",
                backgroundColor: "white",
                color: "#017C57",
                border: "1px solid #017C57",
              }}
              onClick={() => setOpen(false)}
            >
              SAVE
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
