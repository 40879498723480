import { useState, useRef } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { CircularProgress } from "@mui/material";
import { logOut } from "../config/user";
import { AuthServices } from "../services/AuthServices";
import ImagesGallery from "../dashboard/cases/preInitiateModal/ImagesGallery";
import JsPDF from "jspdf";
import FolderIcon from "../assets/folderImage.jpg";
import DownloadIcon from "../assets/downloadIcon.svg";
import EyeIcon from "../assets/eyeIcon.svg";
import "./EditDocuments.scss";

const EditDocuments = () => {
  const [uploadStatus, setUploadStatus] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [openImagesGallery, setOpenImagesGallery] = useState(false);
  const fileInputRef = useRef(null);
  const dragDocument = useRef();
  const draggedOverDocument = useRef();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleSelection = (idx) => {
    const modifiedData = uploadedDocuments[selectedDoc].documentUrls.map(
      (doc, index) => {
        if (idx === index) {
          return { ...doc, isSelected: !doc.isSelected };
        } else {
          return doc;
        }
      }
    );
    uploadedDocuments[selectedDoc].documentUrls = modifiedData;
    setUploadedDocuments([...uploadedDocuments]);
  };
  const handleFolderSort = () => {
    let documentsClone = uploadedDocuments;
    if (dragDocument.current < draggedOverDocument.current) {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current, 1);
    } else {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current + 1, 1);
    }
    console.log("documentsClone:", documentsClone);
    setUploadedDocuments(documentsClone);
  };
  const handleImagesSort = () => {
    let documentsClone = uploadedDocuments[selectedDoc].documentUrls;

    if (dragDocument.current < draggedOverDocument.current) {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current, 1);
    } else {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current + 1, 1);
    }
    let modifiedData = uploadedDocuments;
    modifiedData[selectedDoc].documentUrls = documentsClone;
    setUploadedDocuments([...modifiedData]);
  };
  const uploadImage = async (e) => {
    setUploadStatus(true);
    try {
      let data = new FormData();
      data.append("image", e.target.files[0]);
      const fileName = e.target.files[0].name.slice(0, -4);
      let res = await AuthServices.splitAndUploadImage(data);
      const modifiedData = res?.data?.urls?.map((url) => {
        return { documentUrl: url, isSelected: true };
      });
      let cloneData = [...uploadedDocuments];
      cloneData.push({ documentName: fileName, documentUrls: modifiedData });
      console.log("clone data:", cloneData);
      setUploadedDocuments([...cloneData]);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert("Error occurred while uploading image");
      }
    }
    setUploadStatus(false);
  };
  const generateMergedPDF = async () => {
    setIsDownloading(true);
    let imagesUrl = [];
    for (let doc of uploadedDocuments) {
      for (let image of doc?.documentUrls) {
        if (image.isSelected) {
          imagesUrl.push(image.documentUrl);
        }
      }
    }

    try {
      let response = await AuthServices.loadImagesBase64({
        images: imagesUrl,
      });
      const report = new JsPDF("portrait", "pt", "a4");
      const margin = 0;
      const imgWidth = 595;
      const imgHeight = 845;

      let yPosition = 0;
      if (response?.data) {
        response.data.forEach((img, idx) => {
          if (idx !== 0) {
            report.addPage();
          }
          report.addImage(img, "JPEG", yPosition, margin, imgWidth, imgHeight);

          // yPosition += imgHeight + margin; // Update Y position for the next image
        });
      }
      report.save(`merged-document`);
    } catch (error) {
      window.alert(error);
    }
    setIsDownloading(false);
  };
  const generateSinglePdf = async () => {
    setIsDownloading(true);
    let imagesUrl = [];
    for (let image of uploadedDocuments[selectedDoc]?.documentUrls) {
      if (image.isSelected) {
        imagesUrl.push(image.documentUrl);
      }
    }

    try {
      let response = await AuthServices.loadImagesBase64({
        images: imagesUrl,
      });
      const report = new JsPDF("portrait", "pt", "a4");
      const margin = 0;
      const imgWidth = 595;
      const imgHeight = 845;

      let yPosition = 0;
      if (response?.data) {
        response.data.forEach((img, idx) => {
          if (idx !== 0) {
            report.addPage();
          }
          report.addImage(img, "JPEG", yPosition, margin, imgWidth, imgHeight);

          // yPosition += imgHeight + margin; // Update Y position for the next image
        });
      }
      report.save(`${uploadedDocuments[selectedDoc]?.documentName}`);
    } catch (error) {
      window.alert(error);
    }
    setIsDownloading(false);
  };

  return (
    <div
      style={{
        fontFamily: "Inter",
        padding: 32,
        marginTop: 93,
        width: "100%",
      }}
    >
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Manage Documents
      </span>
      <div className="heading">Manage Documents</div>
      <hr
        style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
      />
      <div
        style={{ border: "1px solid #e4e4e4", marginTop: "24px" }}
        className="rounded-[16px] p-5 h-fit"
      >
        <div className="flex justify-between items-center">
          <div className="flex gap-1 items-end text-xl text-[#191919] font-semibold">
            Uploaded Documents{" "}
            <div className="text-sm font-semibold">
              {uploadedDocuments.length}
            </div>
          </div>
          <div className="flex gap-2 ">
            {uploadStatus ? (
              <CircularProgress />
            ) : (
              <div
                className="flex items-center gap-2 text-white text-sm font-semibold bg-[#017C57] uppercase px-4 py-2 rounded-full cursor-pointer"
                onClick={() => handleButtonClick()}
              >
                <FileUploadOutlinedIcon fontSize="small" /> Upload Document
              </div>
            )}
            {isDownloading ? (
              <CircularProgress />
            ) : (
              uploadedDocuments.length > 0 && (
                <div
                  className="flex items-center gap-2 text-white text-sm font-semibold bg-[#017C57] uppercase px-4 py-2 rounded-full cursor-pointer"
                  onClick={generateMergedPDF}
                >
                  <img src={DownloadIcon} alt="download-icon " />
                  Merge and Download PDF
                </div>
              )
            )}
          </div>
          <input
            type="file"
            onChange={uploadImage}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
        </div>
        {uploadedDocuments.length < 1 && (
          <div className="text-center text-xl font-semibold mt-8">
            {" "}
            Please Upload Documents
          </div>
        )}
        <div className="flex gap-4 flex-wrap my-8">
          {uploadedDocuments?.map((doc, idx) => {
            return (
              <div
                key={idx}
                style={{
                  border:
                    selectedDoc === idx
                      ? "1px solid #017C57"
                      : "1px solid #DEDEDE",
                }}
                className={`w-[200px] cursor-pointer rounded-[8px] py-2 px-4 ${
                  selectedDoc === idx ? "p-1 bg-[#f5f5f5] " : "p-1 bg-white"
                }`}
                onClick={() => setSelectedDoc(idx)}
                draggable
                onDragStart={() => (dragDocument.current = idx)}
                onDragEnter={() => (draggedOverDocument.current = idx)}
                onDragEnd={handleFolderSort}
              >
                <img
                  src={FolderIcon}
                  alt="folder-icon"
                  height={50}
                  width={50}
                />
                <div className="text-sm text-[#191919] font-medium my-1">
                  {doc.documentName}
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ border: "1px solid #DEDEDE" }} className=" my-6" />
        {selectedDoc != null && (
          <div className="flex justify-end py-4">
            <div
              style={{ border: "1px solid #017C57" }}
              className=" w-fit flex item-center gap-2 text-xs text-[#017C57] font-semibold uppercase px-4 py-2 rounded-full cursor-pointer"
              onClick={() => {
                setOpenImagesGallery(true);
                setSelectedImage(0);
              }}
            >
              <img src={EyeIcon} alt="eye-icon" /> Gallery View
            </div>
          </div>
        )}
        {openImagesGallery && (
          <ImagesGallery
            setOpen={setOpenImagesGallery}
            data={uploadedDocuments[selectedDoc]?.documentUrls}
            selectedImage={selectedImage}
          />
        )}
        <div className="flex flex-wrap gap-3 mt-4">
          {selectedDoc !== null &&
            uploadedDocuments[selectedDoc]?.documentUrls?.map((image, idx) => {
              return (
                <div
                  key={idx}
                  style={{ border: "1px solid #D7D7D7" }}
                  className="relative h-[200px] w-[170px] flex items-center justify-center rounded-[8px] mx-1 cursor-pointer hovernow"
                  draggable
                  onDragStart={() => (dragDocument.current = idx)}
                  onDragEnter={() => (draggedOverDocument.current = idx)}
                  onDragEnd={handleImagesSort}
                >
                  <img
                    src={image.documentUrl}
                    alt="document-image"
                    height={180}
                    width={160}
                    onClick={() => {
                      setSelectedImage(idx);
                      setOpenImagesGallery(true);
                    }}
                  />
                  <div className="absolute top-1 right-1 w-[18px]">
                    <input
                      type="checkbox"
                      checked={image.isSelected}
                      onClick={() => handleSelection(idx)}
                    />
                  </div>
                </div>
              );
            })}
        </div>

        {uploadedDocuments.length > 0 && (
          <div className="flex justify-center mt-8">
            {isDownloading ? (
              <CircularProgress />
            ) : (
              <div
                className="flex items-center gap-2 text-white bg-[#017C57] rounded-full px-4 py-2 cursor-pointer"
                onClick={generateSinglePdf}
              >
                <img src={DownloadIcon} alt="download-icon " /> Download PDF
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditDocuments;
