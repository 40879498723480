import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Modal from "@mui/material/Modal";
import { docUploadRefreshContext } from "../contexts/CaseContexts";
import { CircularProgress } from "@mui/material";
import { AuthServices } from "../../../services/AuthServices";
import { CaseServices } from "../../../services/CaseServices";
import { logOut } from "../../../config/user";
import "./uploadDocumentModal.scss";
import CrossIcon from "../../../assets/crossIcon.svg";
import DownloadIcon from "../../../assets/downloadIconGreen.svg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "720px",
  height: "560px",
  borderRadius: "16px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export default function UploadDocumentModal({
  documentDetails,
  setDocumentDetails,
}) {
  const refreshFunction = useContext(docUploadRefreshContext);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [open, setOpen] = useState(false);
  const [docUrl, setDocUrl] = useState("");
  const [uploadstatus, setuploadstatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [documentsUrl, setDocumentsUrl] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const fileInputRef = React.useRef(null);
  const [selectedCount, setSelectedCount] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleSelection = (index) => {
    const modifiedData = documentsUrl.map((document, idx) => {
      if (idx === index) {
        if (document.isSelected) {
          setSelectedCount(selectedCount - 1);
        } else {
          setSelectedCount(selectedCount + 1);
        }
        return { ...document, isSelected: !document.isSelected };
      } else {
        return document;
      }
    });

    setDocumentsUrl(modifiedData);
  };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (selectAll) {
      const modifiedData = documentsUrl.map((document) => {
        return { ...document, isSelected: false };
      });
      setSelectedCount(0);
      setDocumentsUrl(modifiedData);
    } else {
      const modifiedData = documentsUrl.map((document) => {
        return { ...document, isSelected: true };
      });
      setDocumentsUrl(modifiedData);
      setSelectedCount(modifiedData.length);
    }
  };
  const uploadImage = async (e) => {
    setuploadstatus(true);
    try {
      let data = new FormData();
      data.append("image", e.target.files[0]);
      // let res = await AuthServices.uploadImage(data);
      let res = await AuthServices.splitAndUploadImage(data);
      console.log("split upload response:", res);

      // setUploadedImage(uploadedImageUrl);
      setDocUrl(res?.data?.url);
      // setDocumentsUrl([
      //   ...documentsUrl,
      //   {
      //     urlId: "",
      //     sequence: documentDetails.documenturlmasters
      //       ? documentDetails.documenturlmasters.length +
      //         documentsUrl.length +
      //         1
      //       : documentsUrl.length + 1,
      //     documentUrl: res?.data?.url,
      //   },
      // ]);
      const modifiedData = res?.data?.urls?.map((url) => {
        return { documentUrl: url, isSelected: true };
      });
      setDocumentsUrl([...documentsUrl, ...modifiedData]);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert("Error occurred while uploading image");
      }
    }
    setuploadstatus(false);
  };

  const saveUrl = async () => {
    let postData = [];
    let sequence = 0;
    for (let doc of documentsUrl) {
      if (doc.isSelected) {
        sequence++;
        postData.push({
          documentUrl: doc.documentUrl,
          sequence: sequence,
          urlId: "",
        });
      }
    }
    console.log("postData:", postData);
    try {
      setIsSubmitting(true);
      await CaseServices.saveDocumentUrl(documentDetails.id, postData);
      window.alert("Document saved");
      setDocumentsUrl([]);
      setDocumentDetails({ ...documentDetails, documentUrl: docUrl });
      refreshFunction.getMappedDocuments();
      handleClose();
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRemoveDocument = (sequence) => {
    const filteredDocument = documentsUrl.filter(
      (document) => document.sequence !== sequence
    );
    setDocumentsUrl(filteredDocument);
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    //This is done to check selectAll check box when all documents are selected
    if (selectedCount === documentsUrl?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedCount]);
  return (
    <div className="upload-modal-container">
      <button className="open-btn" onClick={handleOpen}>
        <FileUploadOutlinedIcon fontSize="small" /> UPLOAD
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="custom-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width > 640 ? "720px" : "328px",
            height: "560px",
            borderRadius: "16px",
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "Inter, sans-serif",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "24px",
                fontWeight: "600",
                color: "#191919",
              }}
            >
              Upload {documentDetails?.documentName}
            </div>
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "16px",
                fontWeight: "400",
                color: "#797979",
                marginTop: "16px",
              }}
            >
              {documentDetails?.description}
            </div>

            {uploadstatus ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <button
                style={{
                  height: "34px",
                  width: "118px",
                  borderRadius: "32px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                  backgroundColor: "#017c57",
                  color: "white",
                  border: "none",
                  gap: "8px",
                  marginTop: "64px",
                }}
                onClick={() => handleButtonClick()}
              >
                <FileUploadOutlinedIcon fontSize="small" /> UPLOAD
              </button>
            )}
            <input
              type="file"
              onChange={uploadImage}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "12px",
                fontWeight: "400",
                color: "#797979",
                marginTop: "4px",
                marginBottom: "24px",
              }}
            >
              Files Supported: .pdf .png .jpg
            </div>
            {documentsUrl?.length > 0 && (
              <div className="flex gap-2 justify-end items-center w-full px-3">
                <input
                  type="checkbox"
                  checked={selectAll}
                  className="h-[16px] w-[16px]"
                  onClick={handleSelectAll}
                />
                <div>Select All</div>
              </div>
            )}
            <div className="flex flex-wrap items-center justify-between h-max-[450px] overflow-y-scroll w-[100%]  px-2">
              {documentsUrl?.map((document, idx) => {
                return (
                  <div
                    key={idx}
                    style={{ border: "1px solid #E4E4E4" }}
                    className="flex justify-between items-center w-[100%] xl:w-[30%] rounded-[16px] p-4 mt-2 bg-[#F9F9F9]"
                  >
                    <div className="w-[50px] h-[50px]">
                      <img
                        src={document.documentUrl}
                        width={50}
                        height={50}
                        alt="img-icons"
                      />
                    </div>

                    <div className="flex gap-2 items-center">
                      <div>
                        <a href={document.documentUrl} download>
                          <img src={DownloadIcon} alt="download-icon" />
                        </a>
                      </div>
                      {/* <div>
                        <img
                          src={CrossIcon}
                          alt="cross-icon"
                          className="cursor-pointer"
                          onClick={() =>
                            handleRemoveDocument(document.sequence)
                          }
                        />
                      </div> */}
                      <input
                        type="checkbox"
                        checked={document.isSelected}
                        onClick={() => handleSelection(idx)}
                        className="h-[20px] w-[20px]"
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            {isSubmitting ? (
              <div className="flex justify-center mt-2">
                <CircularProgress />
              </div>
            ) : (
              <button
                style={{
                  height: "36px",
                  width: "160px",
                  borderRadius: "32px",
                  marginTop: "56px",
                  fontFamily: "Inter, sans-serif",
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  backgroundColor: "white",
                  color: "#017C57",
                  border: "1px solid #017C57",
                }}
                onClick={saveUrl}
              >
                SAVE
              </button>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
