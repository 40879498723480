import React from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../config/user";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./AddSubadmin.scss";
import { AddUserApi } from "../../../services/AdminServices";

import { emailRegex, passwordRegex } from "../../../utils/formUtils";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .matches(emailRegex, "Invalid Email Address")
    .required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^\+91\d{10}$/, "Phone number must be 10 digits"),
  designation: Yup.string().required("Designation is required"),
  username: Yup.string().required("Username is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      passwordRegex,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

function AddSubadmin() {
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    try {
      const response = await AddUserApi(values);
      if (response.status === 200) {
        window.alert("Subadmin Created Successfully");
        navigate("/stafflist");
      }
      navigate("/stafflist");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else if (error.response && error.response.status === 404) {
        window.alert("Phone Number or email already exists");
      }
    }
  };

  return (
    <div
      style={{
        fontFamily: "Inter",
        padding: 32,
        marginTop: 93,
        width: "100%",
      }}
    >
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Profile &nbsp; &nbsp;/ &nbsp; &nbsp; Add Sub Admin
      </span>
      <div className="heading">Add Sub Admin</div>
      <hr style={{ color: "#E7E7E7" }} />
      <div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            designation: "",
            username: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {({ values, setFieldValue, isSubmitting, errors, touched }) => (
            <Form>
              <Accordion expanded={true} className="accordionbox">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottom: "0px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <text className="personal-details">
                      Personal Details&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                    </text>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="case-form-container1">
                    <label htmlFor="firstName" className="label1">
                      <div className="text-content">First name</div>

                      <Field
                        name="firstName"
                        className="input"
                        placeholder="John"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^A-Za-z ]/g,
                            ""
                          );
                        }}
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="field-error"
                      />
                    </label>
                    <label htmlFor="lastName" className="label1">
                      <div className="text-content">Last name</div>

                      <Field
                        name="lastName"
                        className="input"
                        placeholder="Doe"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^A-Za-z ]/g,
                            ""
                          );
                        }}
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>
                  <div className="case-form-container1">
                    <label htmlFor="phone_otp" className="label1">
                      <div className="text-content">Phone Number</div>
                      <Field
                        type="string"
                        name="phoneNumber"
                        className="input"
                        placeholder="Enter Phone Number"
                        onInput={(e) => {
                          const value = e.target.value;
                          if (!value.startsWith("+91")) {
                            e.target.value =
                              "+91" +
                              value.replace(/\D/g, "").replace(/^91/, "");
                          } else {
                            e.target.value =
                              "+91" + value.slice(3).replace(/\D/g, "");
                          }
                        }}
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="field-error"
                      />
                    </label>
                    <label htmlFor="email" className="label1">
                      <div className="text-content">Official Email</div>
                      <Field
                        type="text"
                        name="email"
                        className="input-field"
                        placeholder="Enter email address"
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                          setFieldValue("username", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>

                  <div
                    style={{ marginTop: "24px" }}
                    className="email-button-container"
                  >
                    {/* <button
                      type="button"
                      style={{ border: "none" }}
                      className="email-button"
                    >
                      SAVE DETAILS
                    </button> */}
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={true} className="accordionbox">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottom: "0px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <text className="personal-details">
                      Professional Details{" "}
                    </text>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="case-form-container1">
                    <label htmlFor="designation" className="label1">
                      <div className="text-content">Designation</div>
                      <Field
                        type="text"
                        name="designation"
                        className="input-field"
                        placeholder="Enter details"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^a-zA-Z\s]/g,
                            ""
                          );
                        }}
                      />
                      <ErrorMessage
                        name="designation"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>
                  {/* <div
                    style={{ marginTop: "24px" }}
                    className="email-button-container"
                  >
                    <button
                      type="button"
                      style={{ border: "none" }}
                      className="email-button"
                    >
                      SAVE DETAILS
                    </button>
                  </div> */}
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={true} className="accordionbox">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottom: "0px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <text className="personal-details">
                      Credentials&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                      &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                    </text>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="case-form-container1">
                    <label htmlFor="username" className="label1">
                      <div className="text-content">Username</div>
                      <Field
                        type="text"
                        name="username"
                        className="input-field"
                        placeholder="Enter username"
                      />
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="field-error"
                      />
                    </label>
                    <label htmlFor="password" className="label1">
                      <div className="text-content">Password</div>
                      <Field
                        type="password"
                        name="password"
                        className="input-field"
                        placeholder="Enter password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>
                  <div
                    style={{ marginTop: "24px" }}
                    className="email-button-container"
                  >
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      style={{ border: "none", cursor: "pointer" }}
                      className="email-button"
                    >
                      SAVE DETAILS
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default AddSubadmin;
