import React, { useState } from "react";
import { Paper } from "@mui/material";
import AddDocuments from "../addcases/AddDocuments";
import "./DirectUploadDocUi.scss";
import { useLocation } from "react-router-dom";

 

const DirectUploadDocUi = () => {
  let location = useLocation()
  let {state} = location

  return (
    <div className="direct-upload-doc-container">
      <AddDocuments caseId={state?.caseId}/>
    </div>
  );
};

export default DirectUploadDocUi;
