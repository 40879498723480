import React, { useState } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import TabStepperUtil from "../../../utils/tabStepperUtil";
import AddCaseDetails from "./AddCaseDetails";
import AddDocumentData from "./AddDocumentData";
import AddDocuments from "./AddDocuments";
import AddHospitalDetails from "./AddHospitalDetails";
import AddPatientDetails from "./AddPatientDetails";
import MapDocumentsNewPage from "../mapdocuments/MapDocumentsNewPage";
import CaseHistory from "../casehistory/CaseHistory";

import "./AddCasesDashboard.scss";
const AddCasesDashboard = () => {
  const [value, setValue] = React.useState("HOSPITAL DETAILS");
  const [patientId, setPatientId] = useState("");
  const [caseId, setCaseId] = useState("");
  const [caseDetails, setCaseDetails] = useState({});
  const [hospitalid, sethospitalid] = useState("");
  let componentWithoutPage = ["MAP DOCUMENTS", "CASE HISTORY"];
  let children = [
    <AddHospitalDetails
      setValue={setValue}
      setCaseId={setCaseId}
      sethospitalid={sethospitalid}
      functionalityType="ADD"
    />,
    <AddPatientDetails
      setValue={setValue}
      setCaseId={setCaseId}
      setPatientId={setPatientId}
      hospitalid={hospitalid}
      functionalityType="ADD"
    />,
    <AddCaseDetails
      setValue={setValue}
      setCaseId={setCaseId}
      patientId={patientId}
      caseDetails={caseDetails}
      hospitalid={hospitalid}
      setCaseDetails={setCaseDetails}
      functionalityType="ADD"
    />,
    <MapDocumentsNewPage
      setValue={setValue}
      setCaseId={setCaseId}
      caseId={caseId}
      functionalityType="ADD"
    />,
    // <AddDocumentData setValue={setValue} caseId={caseId} />,
    <AddDocuments caseId={caseId} functionalityType="ADD" />,
    <CaseHistory functionalityType="ADD" />,
  ];
  let labels = [
    "HOSPITAL DETAILS",
    "PATIENT DETAILS",
    // "CASE DETAILS",
    // "MAP DOCUMENTS",
    // "DOCUMENT DATA",
    // "ADD DOCUMENTS",
    // "CASE HISTORY"
  ];
  return (
    <div className="manage-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Profile &nbsp; &nbsp;/ &nbsp; &nbsp; Add Cases
      </span>
      <div className="heading">
        Add Case
        {/* <div className="btn-container" style={{justifyContent:'flex-start'}}>
          <select name="" className="outlined-btn" style={{width:'163px'}}>
            <option value="">ASSIGN TEAM</option>
          </select>
          <select name="" className="outlined-btn" style={{width:'196px'}}>
            <option value="">ASSIGN MANAGER</option>
          </select>
      </div> */}
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          marginBottom: "20px",
        }}
      />
      <TabStepperUtil
        children={children}
        labels={labels}
        value={value}
        setValue={setValue}
        componentWithoutPage={componentWithoutPage}
        handleChageDisabled="true"
      />
    </div>
  );
};

export default AddCasesDashboard;
