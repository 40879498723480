import { createSlice } from "@reduxjs/toolkit";

export const roleSlice = createSlice({
  name: "currentRole",
  initialState: {
    value: "",
    selectedTab: "HOSPITAL DETAILS",
    isOutsideUser: false,
  },
  reducers: {
    selectRole: (state, action) => {
      state.value = action.payload;
    },
    selectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setOutsideUser: (state, action) => {
      state.isOutsideUser = action.payload;
    },
  },
});

export const { selectRole, selectedTab, setOutsideUser } = roleSlice.actions;

export default roleSlice.reducer;
