import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DocumentDetailBox from "./documentDetailBox";

const EnhancementDocuments = ({ ENHANCEMENTDOCUMENTS }) => {
  const enhancementDocuments = ENHANCEMENTDOCUMENTS;
  const [openAccordion, setOpenAccordion] = useState(0);
  return (
    <div>
      {enhancementDocuments?.map((enhanceDoc, idx) => {
        return (
          <div key={idx} className="mt-3">
            <Accordion
              expanded={idx === openAccordion ? true : false}
              className="accordionbox"
              style={{ borderRadius: "16px" }}
              onClick={() => setOpenAccordion(idx)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="font-semibold text-[#191919]">
                    {enhanceDoc.key}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-wrap gap-[2%]">
                  {enhanceDoc?.value?.map((document, idx) => {
                    return (
                      <DocumentDetailBox key={idx} docDetails={document} />
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default EnhancementDocuments;
