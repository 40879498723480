import { useState, useEffect } from "react";
import CloseIcon from "../../assets/crossIcon.svg";

const SentNotifications = ({
  setOpen,
  sentNotificationList,
  setResendNotification,
}) => {
  console.log("sent notification list:", sentNotificationList);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-[#017C5780] flex justify-center items-center z-10">
      <div className="relative bg-white rounded-[16px] w-[90%] h-fit xl:w-[90%] p-4 xl:p-8 mt-[94px]">
        <div
          className="absolute top-4 right-4 xl:top-7 xl:right-8 cursor-pointer"
          onClick={() => setOpen(false)}
        >
          <img src={CloseIcon} height={30} width={30} alt="close-icon" />
        </div>
        <div className="text-xl text-[#191919] font-semibold">
          Sent Notifications
        </div>
        <div className="grid grid-cols-3 text-[18px] text-[#191919] font-semibold mt-4 mb-3">
          <div className="pl-2">Title</div>
          <div>Body</div>
          <div>To</div>
        </div>
        <div className=" h-max-[90%] overflow-y-scroll text-[#797979] text-[18px] ">
          {sentNotificationList?.map((notification, idx) => {
            return (
              <div
                key={idx}
                className={`grid grid-cols-3 py-6 px-2 rounded-[8px] cursor-pointer ${
                  idx % 2 === 0 ? "bg-[#F9F9F9]" : "bg-white"
                }`}
                onClick={() => {
                  setResendNotification(notification);
                  setOpen(false);
                }}
              >
                <div>{notification.title}</div>
                <div>{notification.body}</div>
                <div>{notification?.to?.toString()}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SentNotifications;
