import { useState, useEffect } from "react";
import CloseIcon from "../../../assets/crossIcon.svg";
import { CaseServices } from "../../../services/CaseServices";

const MapDocumentToUrl = ({ caseId, selectedDoc, isOpen }) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [mappedDocuments, setMappedDocuments] = useState(null);
  const [documentNameList, setDocumentNameList] = useState(null);

  const handleDocumentSelect = async (documentId, url, sequence) => {
    const data = [
      {
        sequence: sequence,
        documentUrl: url,
      },
    ];
    try {
      const response = await CaseServices.saveDocumentUrl(documentId, data);
      window.alert(response.data.message);
      isOpen(false);
      console.log("documetn url save response:", response);
    } catch (error) {
      window.alert(error);
    }
  };
  const getMappedDocuments = async () => {
    try {
      const response = await CaseServices.getMappedDocuments(caseId);
      const data = [];
      for (let i in response?.data?.mappedDocuments) {
        let tempData = { key: i, value: response?.data?.mappedDocuments[i] };
        data.push(tempData);
      }
      console.log("mapped documents:", response?.data?.mappedDocuments);
      setMappedDocuments(data);
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    if (selectedDocumentType) {
      console.log("selected document type:", selectedDocumentType);
      for (let i of mappedDocuments) {
        if (i.key === selectedDocumentType) {
          setDocumentNameList(i.value);
        }
      }
    }
  }, [selectedDocumentType]);
  useEffect(() => {
    getMappedDocuments();
  }, []);
  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-[#017C5780] z-20">
      <div className="relative bg-white rounded-[16px] w-[90%] h-[30%] xl:w-[30%] p-4 xl:p-8">
        <div
          className="absolute top-4 right-4 xl:top-7 xl:right-8 cursor-pointer"
          onClick={() => isOpen(false)}
        >
          <img src={CloseIcon} height={30} width={30} alt="close-icon" />
        </div>
        <div className="flex flex-col xl:flex-row gap-4 mt-12">
          <select
            name="documentType"
            className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[100%] xl:w-[50%] "
            onChange={(e) => setSelectedDocumentType(e.target.value)}
          >
            <option value="">Select Document Type</option>
            {mappedDocuments &&
              mappedDocuments.length > 0 &&
              mappedDocuments?.map((document, idx) => {
                return (
                  <option key={idx} value={document.key}>
                    {document.key}
                  </option>
                );
              })}
          </select>
          <select
            name="documentName"
            className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[100%] xl:w-[50%]"
            onChange={(e) =>
              handleDocumentSelect(
                e.target.value,
                selectedDoc.documentUrl,
                selectedDoc.sequence
              )
            }
          >
            <option value="">Select Document Name</option>{" "}
            {documentNameList &&
              documentNameList.length > 0 &&
              documentNameList?.map((doc, idx) => {
                return (
                  <option key={idx} value={doc.id}>
                    {doc.documentName}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
    </div>
  );
};

export default MapDocumentToUrl;
