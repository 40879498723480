import { createSlice } from "@reduxjs/toolkit";

export const caseDetailSlice = createSlice({
  name: "caseDetail",
  initialState: {
    caseId: "",
  },
  reducers: {
    updateCaseId: (state, action) => {
      state.caseId = action.payload;
    },
  },
});

export const { updateCaseId } = caseDetailSlice.actions;

export default caseDetailSlice.reducer;
