import React from 'react'
import { Paper } from '@mui/material'
import CallHistory from './callhistory/CallHistory'
import ChatHistory from './chathistory/ChatHistory'
import WifiCallingOutlinedIcon from '@mui/icons-material/WifiCallingOutlined'
import './CaseHistory.scss'

const CaseHistory = () => {
  return (
    <div>
       <Paper className='case-history-container'>
       <table className='table'>
        <tbody>
          <tr className='table-body'>
            <td className='first-cell'>SLS5R32464</td>
            <td>Sainad David</td>
            <td>2354</td>
            <td>LK Hospital</td>
            <td>Noida</td>
            <td>M</td>
            <td>25</td>
            <td>10/12/2023</td>
            <td>
              <button className='filled-btn'>Initiate</button>
            </td>
            <td><WifiCallingOutlinedIcon sx={{height:'16px', color:'#017C57'}}/></td>
          </tr>
        </tbody>
       </table>
       </Paper>
       <CallHistory/>
       <ChatHistory/>
    </div>
  )
}

export default CaseHistory
