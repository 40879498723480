import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./Setparamtermodal.scss";
import { CaseServices } from "../../../services/CaseServices";
import { useState } from "react";
import { useEffect } from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { logOut } from "../../../config/user";
import CustomPagination from "../../../utils/paginationUtils";
import { CircularProgress } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1100px",
  height: "800px",
  borderRadius: "16px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

export default function Setparamtermodal({ caseId, setDocuments, documents }) {
  const [open, setOpen] = React.useState(false);
  const [defaultDocuments, setDefaultDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [totalPages, setTotalPages] = useState(10);
  const [page, setPage] = useState(1);
  let searchRef = React.useRef();
  let limit = 10;
  const [selectedDocumentsLocal, setSelectedDocumentsLocal] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const defaultDocs = async () => {
    setIsLoading(true);
    try {
      let documentsResponse =
        await CaseServices.defaultDocumentsWithoutGrouping(
          page,
          limit,
          searchKey
        );
      if (documentsResponse?.data?.list) {
        setDefaultDocuments(documentsResponse.data.list);
        setTotalPages(documentsResponse.data.totalPages);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    defaultDocs();
  }, [page, searchKey]);

  const addselect = (doc) => {
    const isDocPresent = documents[doc.documentType].findIndex(
      (document) => document.id === doc.id
    );
    if (isDocPresent === -1) {
      setSelectedDocumentsLocal((prevDocuments) => [...prevDocuments, doc]);
    }
  };
  const handleSave = () => {
    let myDocs = { ...documents };
    selectedDocumentsLocal.forEach((doc) => {
      if (doc.documentType === "ADMISSIONDOCUMENT") {
        if (!myDocs.ADMISSIONDOCUMENT) {
          myDocs.ADMISSIONDOCUMENT = [];
        }
        myDocs.ADMISSIONDOCUMENT.push(doc);
      } else if (doc.documentType === "DURINGTREATMENTDOCUMENT") {
        if (!myDocs.DURINGTREATMENTDOCUMENT) {
          myDocs.DURINGTREATMENTDOCUMENT = [];
        }
        myDocs.DURINGTREATMENTDOCUMENT.push(doc);
      } else if (doc.documentType === "ONDISCHARGEDOCUMENT") {
        if (!myDocs.ONDISCHARGEDOCUMENT) {
          myDocs.ONDISCHARGEDOCUMENT = [];
        }
        myDocs.ONDISCHARGEDOCUMENT.push(doc);
      }
    });

    setSelectedDocumentsLocal([]);
    setDocuments(myDocs);
    setOpen(false);
  };

  const handleSearch = (e) => {
    if (searchRef.current) {
      clearInterval(searchRef.current);
    }
    searchRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 1000);
  };

  return (
    <div className="parameter-modal-container">
      <button
        className="filled-btn"
        style={{ width: "172px" }}
        onClick={handleOpen}
      >
        Selected Documents
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="custom-modal"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "Inter, sans-serif",
              height: "100%",
            }}
          >
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "24px",
                fontWeight: "600",
                color: "#191919",
                textAlign: "center",
              }}
            >
              Set Parameters
            </div>
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "16px",
                fontWeight: "400",
                color: "#797979",
                textAlign: "center",
                // marginTop: "16px",
              }}
            >
              This is a placeholder text which is suppose to mean nothing
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                className="input-search"
                placeholder="Search anything"
                style={{
                  border: "1px solid #e4e4e4",
                  height: "36px",
                  width: "350px",
                  borderRadius: "8px",
                  padding: "8px",
                  marginTop: "32px",
                  fontFamily: "Inter",
                  justifyContent: "center",
                }}
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <div className="sub-heading">
              <select className="outlined-btn" style={{ width: "137px" }}>
                <option>Document Type</option>
              </select>
              <select className="outlined-btn" style={{ width: "171px" }}>
                <option>Procedure ID</option>
              </select>
              <select className="outlined-btn" style={{ width: "270x" }}>
                <option>Procedure Name</option>
              </select>
              <select className="outlined-btn" style={{ width: "143px" }}>
                <option>Implants</option>
              </select>
              <select className="outlined-btn" style={{ width: "184px" }}>
                <option>Stratification</option>
              </select>
            </div>
            <hr
              style={{
                color: "#E7E7E7",
                margin: "32px 0",
              }}
            />
            <table className="table-container">
              <thead>
                <tr className="table-head">
                  <th align="start" className="cell">
                    Sr. No.
                  </th>
                  <th className="cell">PROCEDURE ID</th>
                  <th className="cell" align="center"></th>
                  <th className="cell">Document Type</th>
                  <th className="cell">Document Name</th>
                  <th className="cell">Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="6" align="center">
                      <CircularProgress />
                    </td>
                  </tr>
                ) : (
                  defaultDocuments &&
                  defaultDocuments.map((doc, idx) => {
                    return (
                      <tr
                        key={idx}
                        className="table-body"
                        style={
                          idx % 2 !== 0
                            ? {
                                backgroundColor: "#F9F9F9",
                                borderRadius: "8px",
                              }
                            : {}
                        }
                      >
                        <td className="cell"> {idx + 1}.</td>
                        <td className="cell">{doc.procedureId}</td>
                        <td className="cell" style={{ fontSize: "16px" }}></td>

                        <td className="cell"> {doc.documentType}</td>
                        <td className="cell"> {doc.documentName}</td>
                        <td className="cell">
                          <button
                            onClick={() => addselect(doc)}
                            style={
                              selectedDocumentsLocal.includes(doc)
                                ? {
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    color: "#CF3636",
                                    border: "none",
                                    background: "none",
                                  }
                                : {
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    lineHeight: "18px",
                                    letterSpacing: "0.8px",
                                    textAlign: "center",
                                    backgroundColor: "#017C57",
                                    width: "90px",
                                    height: "34px",
                                    borderRadius: "32px",
                                    color: "White",
                                    border: "none",
                                  }
                            }
                          >
                            {selectedDocumentsLocal.includes(doc) ? (
                              <DeleteOutlinedIcon />
                            ) : (
                              "SELECT"
                            )}
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <CustomPagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
              }}
            >
              <button
                onClick={handleSave}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "34px",
                  width: "160px",
                  borderRadius: "32px",
                  fontFamily: "Inter, sans-serif",
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  backgroundColor: "#017C57",
                  marginBottom: "40px",
                  color: "white",
                  border: "none",
                }}
              >
                SAVE
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
