import { useState, useEffect } from "react";
import { RecordingServices } from "../../../services/RecordingServices";
import { CircularProgress } from "@mui/material";
import CloseIcon from "../../../assets/crossIcon.svg";
const CaseRecordings = ({ caseId, isOpen }) => {
  const [recordingData, setRecordingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getCaseRecordings = async () => {
    setLoading(true);
    try {
      const response = await RecordingServices.getCallRecordings(caseId);
      console.log("response:", response);
    } catch (error) {
      window.alert(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCaseRecordings();
  }, []);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="fixed top-5 left-0 w-screen h-screen flex justify-center items-center bg-[#017C5780] z-10">
      <div className="relative bg-white rounded-[16px] w-[90%] xl:w-[70%] h-[80%] p-4 xl:p-8">
        <div className="absolute flex gap-2 top-4 right-4 xl:top-7 xl:right-8 cursor-pointer">
          <img
            src={CloseIcon}
            height={30}
            width={30}
            alt="close-icon"
            onClick={() => isOpen(null)}
          />
        </div>
      </div>
    </div>
  );
};

export default CaseRecordings;
