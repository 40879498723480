import React, { useState } from "react";
import "./CaseStatus.scss";
import { ChartLabel, PieChart } from "../utils/chartUtils";
import LaunchIcon from "@mui/icons-material/Launch";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Arrow from "../assets/Arrow.png";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "react-circular-progressbar/dist/styles.css";
import RevenueAnalytics from "./RevenueAnalytics";

const CustomTableCell = styled(TableCell)({
  borderBottom: "none",
  padding: "8px", // Adjust padding if needed
  color: "rgba(121, 121, 121, 1)",
});

const CustomSelect = styled(Select)(({ theme }) => ({
  width: "162px",
  height: "34px",
  borderRadius: "32px",
  marginRight: "100px", // Ensure proper spacing with units
  border: "1px solid rgba(1, 124, 87, 1)",
  padding: "8px 16px",
  gap: "8px",
  color: "rgba(121, 121, 121, 1)",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(1, 124, 87, 1)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(1, 124, 87, 1)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(1, 124, 87, 1)",
  },
  "& .MuiSvgIcon-root": {
    color: "rgba(1, 124, 87, 1)",
  },
}));

function App() {
  const [cases, setCases] = useState([
    {
      id: 1,
      caseNumber: "SLS5R32464",
      team: "Sainad David",
      deadline: "2h 30m",
      estExecutionTime: "1h 30m",
      status: "Pending",
    },
    {
      id: 2,
      caseNumber: "SLS5R32464",
      team: "Sainad David",
      deadline: "2h 30m",
      estExecutionTime: "1h 30m",
      status: "Active",
    },
    {
      id: 3,
      caseNumber: "SLS5R32464",
      team: "Sainad David",
      deadline: "2h 30m",
      estExecutionTime: "1h 30m",
      status: "Pending",
    },
    {
      id: 4,
      caseNumber: "SLS5R32464",
      team: "Sainad David",
      deadline: "2h 30m",
      estExecutionTime: "1h 30m",
      status: "Active",
    },
    {
      id: 4,
      caseNumber: "SLS5R32464",
      team: "Sainad David",
      deadline: "2h 30m",
      estExecutionTime: "1h 30m",
      status: "Active",
    },
    {
      id: 3,
      caseNumber: "SLS5R32464",
      team: "Sainad David",
      deadline: "2h 30m",
      estExecutionTime: "1h 30m",
      status: "Pending",
    },
    {
      id: 3,
      caseNumber: "SLS5R32464",
      team: "Sainad David",
      deadline: "2h 30m",
      estExecutionTime: "1h 30m",
      status: "Pending",
    },
    {
      id: 3,
      caseNumber: "SLS5R32464",
      team: "Sainad David",
      deadline: "2h 30m",
      estExecutionTime: "1h 30m",
      status: "Pending",
    },
    {
      id: 4,
      caseNumber: "SLS5R32464",
      team: "Sainad David",
      deadline: "2h 30m",
      estExecutionTime: "1h 30m",
      status: "Settled",
    },
    {
      id: 4,
      caseNumber: "SLS5R32464",
      team: "Sainad David",
      deadline: "2h 30m",
      estExecutionTime: "1h 30m",
      status: "Settled",
    },
  ]);

  const [status, setStatus] = useState("");

  const handleDropdownStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Pending":
        return {
          backgroundColor: "rgba(255, 243, 198, 1)",
          padding: "12px 16px",
          borderRadius: "24px",
          width: "90px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "rgba(188, 160, 60, 1)",
          fontFamily: "Inter",
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.5px",
          textAlign: "center",
        };
      case "Active":
        return {
          backgroundColor: "rgba(205, 250, 244, 1)",
          padding: "12px 16px",
          borderRadius: "24px",
          width: "90px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "rgba(1, 124, 87, 1)",
          fontFamily: "Inter",
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.5px",
          textAlign: "center",
        };
      case "Settled":
        return {
          backgroundColor: "rgba(1, 124, 87, 1)",
          padding: "12px 16px",
          borderRadius: "24px",
          width: "90px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          fontFamily: "Inter",
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.5px",
          textAlign: "center",
        };
      default:
        return {};
    }
  };

  return (
    <div className="case-status-container">
      <div className="header">
        <span className="case">Case Status</span>
        <select className="dropdown">
          <option>This Month</option>
        </select>
      </div>
      <hr
        style={{
          marginTop: "30px",
          border: "1px solid rgba(228, 228, 228, 1)",
        }}
      />

      <div className="Activecases">
        <div className="chart-and-counts">
          <div className="counts">
            <div className="total">
              <ChartLabel
                label="Total Settled Cases"
                color="rgba(205, 250, 244, 1)"
                style={{ borderRadius: "8px" }}
              />
              <div className="point"></div> {/* Ensure this div is styled */}
              <span className="case-count">999</span>
            </div>
            <div className="total">
              <ChartLabel
                label="Total Active Cases"
                color="rgba(1, 124, 87, 1)"
              />
              <div className="point2"></div> {/* Ensure this div is styled */}
              <span className="case-count">999</span>
            </div>
            <div className="total">
              <ChartLabel
                label="Total Pending Cases"
                color="rgba(255, 240, 180, 1)"
              />
              <div className="point2"></div> {/* Ensure this div is styled */}
              <span className="case-count">999</span>
            </div>
          </div>

          <PieChart
            chartData={{
              labels: ["Total Cases", "Total Pending cases"],
              datasets: [
                {
                  label: "Users Gained",
                  data: [10, 20, 40],
                  backgroundColor: [
                    "#cdfaf4",
                    "#FFF0B4",
                    "rgba(1, 124, 87, 1)",
                  ],
                  borderColor: "white",
                  borderWidth: 1,
                  borderRadius: 80,
                },
              ],
            }}
          />
        </div>
        <div className="total-container">
          <div className="totalcases">
            <span
              className="total"
              style={{
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "19.36px",
              }}
            >
              Delayed Cases
            </span>
            <button className="total-button">
              <span className="expand">Expand</span>
              <LaunchIcon
                style={{ color: "rgba(1, 124, 87, 1)", marginLeft: "8px" }}
              />
            </button>
          </div>
          <hr
            style={{
              marginTop: "20px",
              border: "1px solid rgba(228, 228, 228, 1)",
            }}
          />
        </div>

        <div className="case-details">
          <div className="table-wrapper">
            <Table
              sx={{ border: "none", minWidth: "700px" }}
              aria-label="case details table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div
                      style={{
                        width: "112px",
                        height: "41px",
                        display: "flex",
                        justifyContent: "space-between",
                        fontWeight: "bold",
                      }}
                    >
                      Case Number
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      className="team-manager"
                      style={{
                        width: "112px",
                        height: "41px",
                        fontWeight: "bold",
                      }}
                    >
                      Team Manager
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        width: "112px",
                        height: "41px",
                        fontWeight: "bold",
                      }}
                    >
                      Deadline
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        width: "112px",
                        height: "41px",
                        fontWeight: "bold",
                      }}
                    >
                      Est. Execution Time
                    </div>
                  </TableCell>
                  <TableCell>
                    <div>
                      <select
                        name="dropdown"
                        style={{
                          width: "162px",
                          height: "34px",
                          top: "3.41px",
                          borderRadius: "32px",
                          border: "1px solid rgba(1, 124, 87, 1)",
                          textAlign: "center",
                          fontFamily: "Inter",
                          fontWeight: 600,
                          fontSize: "14px",
                          lineHeight: "18px",
                          letterSpacing: "0.8px",
                          color: "rgba(1, 124, 87, 1)",
                        }}
                      >
                        <option value="option1">CASE STATUS</option>
                      </select>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {cases.map((caseItem, index) => (
                  <TableRow
                    key={caseItem.id}
                    style={{
                      backgroundColor:
                        index % 2 === 1 ? "rgba(249, 249, 249, 1)" : "white",
                    }}
                  >
                    <CustomTableCell>{caseItem.caseNumber}</CustomTableCell>
                    <CustomTableCell>{caseItem.team}</CustomTableCell>
                    <CustomTableCell>{caseItem.deadline}</CustomTableCell>
                    <CustomTableCell>
                      {caseItem.estExecutionTime}
                    </CustomTableCell>
                    <CustomTableCell>
                      <span style={getStatusStyle(caseItem.status)}>
                        {caseItem.status}
                      </span>
                    </CustomTableCell>
                    <CustomTableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: "46px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgressbarWithChildren
                            value={60} // value in percentage
                            strokeWidth={13}
                            styles={{
                              path: {
                                stroke: "#017C57",
                                strokeLinecap: "round",
                                transformOrigin: "center center",
                              },
                              trail: {
                                stroke: "#CDFAF4",
                                transformOrigin: "center center",
                              },
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: "10px",
                                color: "#017C57",
                                fontFamily: "inter, sans-serif",
                                fontWeight: 500,
                              }}
                            >
                              6/10
                            </div>
                          </CircularProgressbarWithChildren>
                        </div>
                        <ArrowRightAltIcon
                          style={{
                            width: "12px",
                            height: "12px",
                            marginLeft: "5px",
                            color: "rgba(1, 124, 87, 1)",
                          }}
                        />
                      </div>
                    </CustomTableCell>
                    <CustomTableCell></CustomTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      <div className="viewmore">
        <span className="button">VIEW MORE</span>
        <img src={Arrow} className="arrow" alt="Frame" />
      </div>
    </div>
  );
}

export default App;
