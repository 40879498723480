import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { Field, Formik, Form } from "formik";
import { string, object, number } from "yup";
import { PatientServices } from "../../../services/PatientServices";
import Modal from "@mui/material/Modal";
import { logOut } from "../../../config/user";
import "./GeneralFindingsModal.scss";
import * as Yup from "yup";

const headStyle = {
  color: "#191919",
  fontFamily: "inter",
  fontSize: "24px",
  fontWeight: "500",
  marginTop: "40px",
};

const inputStyle = {
  height: "40px",
  borderRadius: "8px",
  border: "1px solid #e4e4e4",
  paddingLeft: "8px",
  fontFamily: "inter",
  fontSize: "14px",
  fontWeight: "400",
  color: "#797979",
  width: "100%",
};

const subHeadStyle = {
  paddingLeft: "8px",
  fontFamily: "inter",
  fontSize: "14px",
  fontWeight: "400",
  color: "#797979",
  display: "flex",
  alignItems: "center",
  gap: "6px",
};

const buttonStyle = {
  height: "34px",
  width: "160px",
  borderRadius: "32px",
  backgroundColor: "#017c57",
  border: "none",
  color: "white",
  fontFamily: "inter",
  fontSize: "14px",
  fontWeight: "500",
  margin: "48px",
};
const bottomLineStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: "inter",
  fontSize: "14px",
  fontWeight: "400",
  padding: "8px",
  gap: "16px",
  color: "#797979",
};

export default function GeneralFindingsModal({
  patientId,
  patientDetails,
  updated,
  setUpdated,
  functionalityType,
}) {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const boxStyle = {
    height: "88px",
    border: "1px solid #E4E4E4",
    marginTop: "24px",
    backgroundColor: "#F9F9F9",
    fontFamily: "inter",
    width: width > 640 ? "48%" : "100%",
    borderRadius: "8px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };
  let initialValues = {
    patientId: patientId,
    temperature: null,
    temperatureUnit: null,
    pulseRate: null,
    leftArmSystolic: null,
    leftArmDiastolic: null,
    bpLtArm: null,
    rightArmSystolic: null,
    rightArmDiastolic: null,
    bpRtArm: null,
    height: null,
    weight: null,
    bmi: null,
    pallor: null,
    cyanosis: null,
    fingerOrToesClubbing: null,
    lymphadenopathy: null,
    feetOedema: null,
    malnutrition: null,
    dehydration: null,
    respirationRate: null,
  };

  if (
    functionalityType === "EDIT" &&
    patientDetails &&
    patientDetails?.presentillnesses &&
    patientDetails?.presentillnesses.length > 0
  ) {
    initialValues = {
      ...patientDetails?.presentillnesses[0],
      patientId: patientId,
    };
  }

  const errorValidation = object({
    temperatureUnit: string().required("Temperature unit is required"),
    pulseRate: number()
      .min(30, "Pulse rate can not be less than 30")
      .max(320, "Pulse rate can not greater than 320")
      .required("Pulse rate is required"),
    leftArmSystolic: number()
      .max(320, "Systolic value can not exceed 320")
      .min(30, "Systolic value should be greater than 30")
      .required("Please Insert Systolic Value"),
    leftArmDiastolic: number()
      .max(140, "Diastolic value can not exceed 140")
      .min(30, "Diastolic value should be greater than 30")
      .required("Please Insert Diastolic Value"),

    temperature: Yup.number().when("temperatureUnit", {
      is: "C",
      then: (schema) =>
        schema
          .max(40, "Temperature cannot exceed 40°C")
          .required("Temperature is required for Celsius"),
      otherwise: (schema) =>
        schema
          .max(105, "Temperature cannot exceed 105°F")
          .required("Temperature is required for Fahrenheit"),
    }),
  });

  const handleSubmit = async (values) => {
    console.log("values:", values);
    setIsSubmitting(true);
    try {
      await PatientServices.addPresentIllness({
        ...values,
        patientId: patientId,
      });
      console.log("data saved");
      window.alert("Data Saved");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  const handleUpdate = async (values) => {
    console.log("values:", values);
    setIsSubmitting(true);
    try {
      await PatientServices.updatePresentIllness({ ...values }, values?.id);
      setUpdated(!updated);
      window.alert("Data updated");
      handleClose();
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <div className="general-modal-container">
      <button className="outline-btn" type="button" onClick={handleOpen}>
        General Findings
        <LaunchOutlinedIcon style={{ height: "14px" }} />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="custom-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width > 640 ? "785px" : "328px",
            height: "700px",
            borderRadius: "16px",
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            overflowY: "auto",
            p: width > 640 ? "48px" : "12px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={headStyle}>History of Present Illnesss</div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={errorValidation}
            validateOnChange={true}
            onSubmit={
              functionalityType === "EDIT" ? handleUpdate : handleSubmit
            }
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div
                  style={{
                    display: width > 640 ? "flex" : "block",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      Temperature (C/F)*
                    </label>

                    <div style={bottomLineStyle}>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="temperatureUnit" value="C" />C
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="temperatureUnit" value="F" />F
                      </div>

                      <Field
                        style={inputStyle}
                        name="temperature"
                        type="number"
                        placeholder="Specify"
                      />
                    </div>
                    {touched.temperatureUnit && errors.temperatureUnit && (
                      <div className="field-error">
                        {errors.temperatureUnit}
                      </div>
                    )}
                    {touched.temperature && errors.temperature && (
                      <div className="field-error">{errors.temperature}</div>
                    )}
                  </div>
                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      Pulse Rate per Minute *
                    </label>
                    <div style={bottomLineStyle}>
                      <Field
                        type="number"
                        style={inputStyle}
                        name="pulseRate"
                        placeholder="Pulse Rate per Minute"
                      />
                    </div>{" "}
                    {touched.pulseRate && errors.pulseRate && (
                      <div className="field-error">{errors.pulseRate}</div>
                    )}
                  </div>
                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      BP LtArm mm/Hg *
                    </label>
                    <div style={bottomLineStyle}>
                      <div>
                        <Field
                          style={inputStyle}
                          name="leftArmSystolic"
                          placeholder="Systolic"
                          type="number"
                          onChange={(e) => {
                            console.log(e.target.name, e.target.value);
                            setFieldValue("leftArmSystolic", e.target.value);
                            setFieldValue(
                              "bpLtArm",
                              `${e.target.value}/${values.leftArmDiastolic}`
                            );
                          }}
                        />
                      </div>

                      <div>
                        <Field
                          style={inputStyle}
                          name="leftArmDiastolic"
                          placeholder="Diastolic"
                          type="number"
                          onChange={(e) => {
                            console.log(e.target.name, e.target.value);
                            setFieldValue("leftArmDiastolic", e.target.value);
                            setFieldValue(
                              "bpLtArm",
                              `${values.leftArmSystolic}/${e.target.value}`
                            );
                          }}
                        />
                      </div>
                    </div>
                    {touched.leftArmSystolic && errors.leftArmSystolic ? (
                      <div className="field-error">
                        {errors.leftArmSystolic}
                      </div>
                    ) : touched.leftArmDiastolic && errors.leftArmDiastolic ? (
                      <div className="field-error">
                        {errors.leftArmDiastolic}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      BP Rt.Arm mm/Hg
                    </label>
                    <div style={bottomLineStyle}>
                      <div>
                        <Field
                          style={inputStyle}
                          name="rightArmSystolic"
                          placeholder="Systolic"
                          type="number"
                          onChange={(e) => {
                            console.log(e.target.name, e.target.value);
                            setFieldValue(
                              "bpRtArm",
                              `${e.target.value}/${values.rightArmDiastolic}`
                            );
                          }}
                        />
                      </div>

                      <div>
                        <Field
                          style={inputStyle}
                          name="rightArmDiastolic"
                          placeholder="Diastolic"
                          type="number"
                          onChange={(e) => {
                            console.log(e.target.name, e.target.value);
                            setFieldValue(
                              "bpRtArm",
                              `${values.rightArmSystolic}/${e.target.value}`
                            );
                          }}
                        />
                      </div>
                    </div>
                    {touched.rightArmSystolic && errors.rightArmSystolic ? (
                      <div className="field-error">
                        {errors.rightArmSystolic}
                      </div>
                    ) : touched.rightArmDiastolic &&
                      errors.rightArmDiastolic ? (
                      <div className="field-error">
                        {errors.rightArmDiastolic}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      Height (in cm)
                    </label>
                    <div style={bottomLineStyle}>
                      <Field
                        style={inputStyle}
                        name="height"
                        placeholder="Specify"
                        type="number"
                      />
                    </div>
                    {touched.height && errors.height && (
                      <div className="field-error">{errors.height}</div>
                    )}
                  </div>
                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      Weight (in KG)
                    </label>
                    <div style={bottomLineStyle}>
                      <Field
                        style={inputStyle}
                        name="weight"
                        placeholder="Specify"
                        type="number"
                      />
                    </div>
                    {touched.weight && errors.weight && (
                      <div className="field-error">{errors.weight}</div>
                    )}
                  </div>
                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      BMI
                    </label>
                    <div style={bottomLineStyle}>
                      <Field
                        style={inputStyle}
                        name="bmi"
                        placeholder="Specify"
                      />
                    </div>
                  </div>
                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      Pallor
                    </label>
                    <div style={bottomLineStyle}>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="pallor" value="YES" />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="pallor" value="NO" />
                        No
                      </div>
                    </div>
                    {touched.pallor && errors.pallor && (
                      <div className="field-error">{errors.pallor}</div>
                    )}
                  </div>

                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      Cyanosis
                    </label>
                    <div style={bottomLineStyle}>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="cyanosis" value="YES" />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="cyanosis" value="NO" />
                        No
                      </div>
                    </div>
                    {touched.cyanosis && errors.cyanosis && (
                      <div className="field-error">{errors.cyanosis}</div>
                    )}
                  </div>

                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      Clubbing of finger/toes
                    </label>
                    <div style={bottomLineStyle}>
                      <div style={{ display: "flex" }}>
                        <Field
                          type="radio"
                          name="fingerOrToesClubbing"
                          value="YES"
                        />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field
                          type="radio"
                          name="fingerOrToesClubbing"
                          value="NO"
                        />
                        No
                      </div>
                    </div>
                    {touched.fingerOrToesClubbing &&
                      errors.fingerOrToesClubbing && (
                        <div className="field-error">
                          {errors.fingerOrToesClubbing}
                        </div>
                      )}
                  </div>
                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      Lymphadenopathy
                    </label>
                    <div style={bottomLineStyle}>
                      <div style={{ display: "flex" }}>
                        <Field
                          type="radio"
                          name="lymphadenopathy"
                          value="YES"
                        />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="lymphadenopathy" value="NO" />
                        No
                      </div>
                    </div>
                    {touched.lymphadenopathy && errors.lymphadenopathy && (
                      <div className="field-error">
                        {errors.lymphadenopathy}
                      </div>
                    )}
                  </div>
                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      Oedema in feet
                    </label>
                    <div style={bottomLineStyle}>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="feetOedema" value="YES" />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="feetOedema" value="NO" />
                        No
                      </div>
                    </div>
                    {touched.feetOedema && errors.feetOedema && (
                      <div className="field-error">{errors.feetOedema}</div>
                    )}
                  </div>
                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      Malnutrition
                    </label>
                    <div style={bottomLineStyle}>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="malnutrition" value="YES" />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="malnutrition" value="NO" />
                        No
                      </div>
                    </div>
                  </div>
                  <div style={boxStyle}>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      Dehydration
                    </label>
                    <div style={bottomLineStyle}>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="dehydration" value="YES" />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="dehydration" value="NO" />
                        No
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      ...boxStyle,
                      width: "100%",
                      height: width ? 640 > "88px" : "96px",
                    }}
                  >
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="toggle" disabled />
                      Respiration (count for a full minute) Rate
                    </label>
                    <div style={bottomLineStyle}>
                      <Field
                        style={inputStyle}
                        name="respirationRate"
                        type="number"
                        placeholder="Specify"
                      />
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    style={buttonStyle}
                  >
                    SAVE
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}
