import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { Field, Formik, Form } from "formik";
import { object, string } from "yup";
import { PatientServices } from "../../../services/PatientServices";
import { logOut } from "../../../config/user";
import Modal from "@mui/material/Modal";
import "./PersonalHistoryModal.scss";

const headStyle = {
  color: "#191919",
  fontFamily: "inter",
  fontSize: "24px",
  fontWeight: "500",
  marginTop: "40px",
};

const subHeadStyle = {
  paddingLeft: "8px",
  fontFamily: "inter",
  fontSize: "14px",
  fontWeight: "400",
  color: "#797979",
  justifyContent: "space-around",
};

const buttonStyle = {
  height: "34px",
  width: "160px",
  borderRadius: "32px",
  backgroundColor: "#017c57",
  border: "none",
  color: "white",
  fontFamily: "inter",
  fontSize: "14px",
  fontWeight: "500",
  margin: "48px",
};

export default function PersonalHistoryModal({
  patientId,
  patientDetails,
  functionalityType,
  setUpdated,
  updated,
}) {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const inputStyle = {
    height: "40px",
    borderRadius: "8px",
    border: "1px solid #e4e4e4",
    paddingLeft: "8px",
    fontFamily: "inter",
    fontSize: "14px",
    fontWeight: "400",
    color: "#797979",
  };
  const boxStyle = {
    height: "88px",
    border: "1px solid #E4E4E4",
    marginTop: "24px",
    backgroundColor: "#F9F9F9",
    fontFamily: "inter",
    width: width > 640 ? "30%" : "100%",
    borderRadius: "8px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };
  const labelStyle = {
    display: "flex",
    flexDirection: "column",
    marginTop: "24px",
    width: width > 640 ? "30%" : "100%",
    gap: "16px",
  };
  const flexStyle = {
    display: width > 640 ? "flex" : "block",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
  };
  const subHeadStyle = {
    paddingLeft: "8px",
    fontFamily: "inter",
    fontSize: "14px",
    fontWeight: "400",
    color: "#797979",
    justifyContent: "space-around",
  };

  let initialValues = {
    patientId: patientId,
    appetite: null,
    diet: null,
    bowels: null,
    nutrition: null,
    medicineAllergy: null,
    allergicMedicineName: null,
    addictions: null,
    allergies: null,
    otherAllergy: null,
    otherAllergyDescription: null,
    alcohol: null,
    tobacco: null,
    packs: null,
    sinceYears: null,
    drugUse: null,
    betelNut: null,
    betelLeaf: null,
  };

  if (
    functionalityType === "EDIT" &&
    patientDetails &&
    patientDetails?.personalhistories &&
    patientDetails?.personalhistories.length > 0
  ) {
    initialValues = {
      ...patientDetails?.personalhistories[0],
      patientId: patientId,
    };
  }

  const errorValidation = object({
    // primaryDiagnosis: string().required("Required Field"),
    // appetite: string().required("Appetite is required"),
    // diet: string().required("Diet details are required"),
    // bowels: string().required("Bowels information is required"),
    // nutrition: string().required("Nutrition status is required"),
    // medicineAllergy: string().required("Medicine allergy status is required"),
    // allergicMedicineName: string().required("Specify allergic medicine(s)"),
    // otherAllergy: string().required("Specify other allergies"),
    // alcohol: string().required("Alcohol consumption status is required"),
    // tobacco: string().required("Tobacco use status is required"),
    // packs: string().required("Number of packs per day is required"),
    // sinceYears: string().required("Years of consumption is required"),
    // drugUse: string().required("Drug use status is required"),
    // betelNut: string().required("Betel nut consumption status is required"),
    // betelLeaf: string().required("Betel leaf consumption status is required"),
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      await PatientServices.addPersonalHistory({
        ...values,
        patientId: patientId || "55f91d70-24e3-4e98-8881-b12a1de29bcb",
      });
      window.alert("Data Saved");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  const handleUpdate = async (values) => {
    setIsSubmitting(true);
    try {
      await PatientServices.updatePersonalHistory({ ...values }, values?.id);
      setUpdated(!updated);
      window.alert("Data updated");
      handleClose();
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <div className="personal-modal-container">
      <button className="outline-btn" type="button" onClick={handleOpen}>
        Personal History
        <LaunchOutlinedIcon style={{ height: "14px" }} />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="custom-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width > 640 ? "900px" : "328px",
            height: "600px",
            borderRadius: "16px",
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            overflowY: "auto",
            p: width > 640 ? "48px" : "16px",
          }}
        >
          <div style={headStyle}>Personal History</div>
          <Formik
            initialValues={initialValues}
            validationSchema={errorValidation}
            onSubmit={
              functionalityType === "EDIT" ? handleUpdate : handleSubmit
            }
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div
                  style={{
                    display: width > 640 ? "flex" : "block",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <label style={labelStyle}>
                    <Field style={inputStyle} name="appetite" as="select">
                      <option>Appetite</option>
                      <option value="NORMAL">NORMAL</option>
                      <option value="LOSS">LOSS</option>
                    </Field>
                  </label>
                  <label style={labelStyle}>
                    <Field style={inputStyle} name="diet" as="select">
                      <option>Diet</option>
                      <option value="VEG">VEG</option>
                      <option value="NONVEG">NONVEG</option>
                    </Field>
                  </label>
                  <label style={labelStyle}>
                    <Field style={inputStyle} name="bowels" as="select">
                      <option>Bowels</option>
                      <option value="REGULAR">REGULAR</option>
                      <option value="IRREGULAR">IRREGULAR</option>
                      <option value="CONSTIPATION">CONSTIPATION</option>
                    </Field>
                  </label>
                  <label style={labelStyle}>
                    <Field style={inputStyle} name="nutrition" as="select">
                      <option>Nutrition</option>
                      <option value="NORMAL">NORMAL</option>
                      <option value="ABNORMAL">ABNORMAL</option>
                    </Field>
                  </label>
                  <div style={boxStyle}>
                    <div style={subHeadStyle}>Known Allergies</div>
                    <div
                      style={{
                        ...flexStyle,
                        ...subHeadStyle,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="allergies" value="YES" />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="allergies" value="NO" />
                        No
                      </div>
                    </div>
                  </div>

                  <div style={boxStyle}>
                    <div style={subHeadStyle}>Habits/Addictions</div>
                    <div style={{ ...flexStyle, ...subHeadStyle }}>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="addictions" value="YES" />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="addictions" value="NO" />
                        No
                      </div>
                    </div>
                  </div>
                </div>
                <div style={headStyle}>Allergy Details</div>
                <div style={flexStyle}>
                  <div
                    style={{
                      ...boxStyle,
                      width: width > 640 ? "48%" : "100%",
                      height: width > 640 ? "88px" : "120px",
                    }}
                  >
                    <div style={subHeadStyle}>Allergic to medicine</div>
                    <div
                      style={{
                        ...flexStyle,
                        ...subHeadStyle,
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Field
                          type="radio"
                          name="medicineAllergy"
                          value="YES"
                        />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="medicineAllergy" value="NO" />
                        No
                      </div>

                      <Field
                        style={inputStyle}
                        name="allergicMedicineName"
                        placeholder="Specify"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      ...boxStyle,
                      width: width > 640 ? "48%" : "100%",
                      height: width > 640 ? "88px" : "140px",
                    }}
                  >
                    <div style={subHeadStyle}>
                      Allergic to substance other than medicine
                    </div>
                    <div
                      style={{
                        ...flexStyle,
                        ...subHeadStyle,
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="otherAllergy" value="YES" />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="otherAllergy" value="NO" />
                        No
                      </div>
                      <Field
                        style={inputStyle}
                        name="otherAllergyDescription"
                        placeholder="Specify"
                      />
                    </div>
                  </div>
                </div>
                <div style={headStyle}>Habbit/Addiction Details</div>
                <div style={flexStyle}>
                  <div
                    style={{
                      ...boxStyle,
                      width: width > 640 ? "27%" : "100%",
                    }}
                  >
                    <div style={subHeadStyle}>Alcohol</div>
                    <div
                      style={{
                        ...flexStyle,
                        ...subHeadStyle,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="alcohol" value="REGULAR" />
                        Regular
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="alcohol" value="OCCASIONAL" />
                        Occasional
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field
                          type="radio"
                          name="alcohol"
                          value="TEETOTALLER"
                        />
                        Teetotaller
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ ...boxStyle, width: width > 640 ? "24%" : "100%" }}
                  >
                    <div style={subHeadStyle}>Tobacco</div>
                    <div
                      style={{
                        ...flexStyle,
                        ...subHeadStyle,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="tobacco" value="SNUFF" />
                        Snuff
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="tobacco" value="CHEWABLE" />
                        Chewable
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="tobacco" value="SMOKING" />
                        Smoking
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ ...boxStyle, width: width > 640 ? "23%" : "100%" }}
                  >
                    <div style={subHeadStyle}>Packs</div>
                    <Field
                      style={inputStyle}
                      name="packs"
                      placeholder="Per Day"
                      type="number"
                    />
                    {touched.packs && errors.packs && (
                      <div className="field-error">{errors.packs}</div>
                    )}
                  </div>
                  <div
                    style={{
                      ...boxStyle,
                      width: width > 640 ? "23%" : "100%",
                    }}
                  >
                    <div style={subHeadStyle}>Years</div>
                    <Field
                      style={inputStyle}
                      name="sinceYears"
                      placeholder="Since Years"
                      type="number"
                    />
                  </div>
                  <div
                    style={{ ...boxStyle, width: width > 640 ? "30%" : "100%" }}
                  >
                    <div style={subHeadStyle}>Drug Use</div>
                    <div
                      style={{
                        ...flexStyle,
                        ...subHeadStyle,
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="drugUse" value="YES" />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="drugUse" value="NO" />
                        No
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ ...boxStyle, width: width > 640 ? "30%" : "100%" }}
                  >
                    <div style={subHeadStyle}>Betel Nut</div>
                    <div style={{ ...flexStyle, ...subHeadStyle }}>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="betelNut" value="YES" />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="betelNut" value="NO" />
                        No
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ ...boxStyle, width: width > 640 ? "30%" : "100%" }}
                  >
                    <div style={subHeadStyle}>Betel Leaf</div>
                    <div style={{ ...flexStyle, ...subHeadStyle }}>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="betelLeaf" value="YES" />
                        Yes
                      </div>
                      <div style={{ display: "flex" }}>
                        <Field type="radio" name="betelLeaf" value="NO" />
                        No
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    style={buttonStyle}
                  >
                    SAVE
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}
