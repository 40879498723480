import { useState, useEffect } from "react";
import CloseIcon from "../../../assets/crossIcon.svg";
import SuggestionBoxUtil from "../../../utils/SuggestionBoxUtil";
import { CaseServices } from "../../../services/CaseServices";
import AddMore from "../../../assets/AddMoreGreen.svg";
import DeleteIcon from "../../../assets/deleteIcon.svg";
import Select from "react-select";
import { CircularProgress } from "@mui/material";
const EditProcedure = ({
  setIsEditOpen,
  specialityParam,
  procedureParam,
  specialityIdParam,
  procedureIdParam,
  compositIdParam,
  type,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [selectedSpeciality, setSelectedSpeciality] = useState(
    specialityParam ? specialityParam : null
  );
  const [selectedProcedure, setSelectedProcedure] = useState(
    procedureParam ? procedureParam : null
  );
  const [procedureInputValue, setProcedureInputValue] = useState(null);
  const [procedureType, setProcedureType] = useState(null);
  const [specialityInputValue, setSpecialityInputValue] = useState(null);
  const [compositId, setCompositId] = useState(
    compositIdParam ? compositIdParam : null
  );
  const [isStratification, setIsStratification] = useState(false);
  const [specialityId, setSpecialityId] = useState(
    specialityIdParam ? specialityIdParam : null
  );
  const [procedureId, setProcedureId] = useState(
    procedureIdParam ? procedureIdParam : null
  );
  const [documentName, setDocumentName] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [documentList, setDocumentList] = useState([]);
  const [implants, setImplants] = useState([]);
  const [nrp, setNrp] = useState(null);
  const [tier1Costing, setTier1Costing] = useState([]);
  const [tier2Costing, setTier2Costing] = useState([]);
  const [tier3Costing, setTier3Costing] = useState([]);
  const [step, setStep] = useState(1);

  const handleChange = (e, idx, type) => {
    const name = e.target.name;
    const value = e.target.value;
    if (type === "tier1") {
      tier1Costing[idx][`${name}`] = value;
      setTier1Costing(tier1Costing);
    } else if (type === "tier2") {
      tier2Costing[idx][`${name}`] = value;
      setTier2Costing(tier2Costing);
    } else if (type === "tier3") {
      tier3Costing[idx][`${name}`] = value;
      setTier3Costing(tier3Costing);
    } else if (type === "implants") {
      implants[idx][`${name}`] = value;
      setImplants(implants);
    }
  };

  const handleAutoSuggestion = (selected, e, inputValue) => {
    console.log("selected", selected);
    if (e.name === "speciality") {
      setSelectedSpeciality(selected.value);
      setSpecialityId(selected.specialityId);
      setSpecialityInputValue(inputValue);
    } else if (e.name === "procedure") {
      setSelectedProcedure(selected.value);
      setProcedureId(selected.procedureId);
      setProcedureType(selected.procedureType);
      setCompositId(selected.id);
      setProcedureInputValue(inputValue);
    }
  };
  const handleTier1AddMore = () => {
    if (tier1Costing) {
      let data = [...tier1Costing, { name: "", price: "" }];
      setTier1Costing([...data]);
    } else {
      let data = [{ name: "", price: "" }];
      setTier1Costing([...data]);
    }
  };

  const handleTier2AddMore = () => {
    if (tier2Costing) {
      let data = [...tier2Costing, { name: "", price: "" }];
      setTier2Costing([...data]);
    } else {
      let data = [{ name: "", price: "" }];
      setTier2Costing([...data]);
    }
  };
  const handleTier3AddMore = () => {
    if (tier3Costing) {
      let data = [...tier3Costing, { name: "", price: "" }];
      setTier3Costing([...data]);
    } else {
      let data = [{ name: "", price: "" }];
      setTier3Costing([...data]);
    }
  };
  const handleImplantsAddMore = () => {
    if (implants) {
      let data = [...implants, { name: "", price: "" }];
      setImplants([...data]);
    } else {
      let data = [{ name: "", price: "" }];
      setImplants([...data]);
    }
  };
  const handleAddDocument = () => {
    setDocumentList([
      ...documentList,
      {
        documentName: documentName,
        documentType: documentType,
        procedureId: procedureId,
        procedureName: selectedProcedure,
        specialityId: specialityId,
        specialityName: selectedSpeciality,
        required: true,
      },
    ]);
    setDocumentName(null);
    setDocumentType(null);
    setProcedureId(null);
    setSelectedProcedure(null);
    setSpecialityId(null);
    setSelectedSpeciality(null);
  };
  const handleDocumentRemove = (idx) => {
    setDocumentList((prev) => prev.splice(idx, 1));
  };
  const handleSaveChanges = async () => {
    setSubmitting(true);
    const data = [
      {
        speciality: specialityInputValue
          ? specialityInputValue
          : selectedSpeciality,
        specialityId: specialityId,
        procedure: procedureInputValue
          ? procedureInputValue
          : selectedProcedure,
        procedureId: procedureId,
        procedureType: procedureType,
        tier1: tier1Costing,
        tier2: tier2Costing,
        tier3: tier3Costing,
        implants: implants,
        nrp: nrp,
      },
    ];
    try {
      const response = await CaseServices.addDefaultProcedureCost(data);
      window.alert(response.data.message);
      setIsEditOpen(false);
    } catch (error) {
      window.alert(error);
    }
    setSubmitting(false);
  };
  const getProcedureAllTierCost = async () => {
    try {
      const response = await CaseServices.procedureAllTierCost(compositId);
      setImplants(response?.data?.procedureCost?.implants);
      setNrp(response?.data?.procedureCost?.nrp);
      setTier1Costing(response?.data?.procedureCost?.tier1);
      setTier2Costing(response?.data?.procedureCost?.tier2);
      setTier3Costing(response?.data?.procedureCost?.tier3);
      if (Array.isArray(response?.data?.procedureCost?.tier1)) {
        setIsStratification(true);
      }
      console.log("procedure all tier cost:", response.data.procedureCost);
    } catch (error) {
      window.alert(error);
    }
  };
  const handleDocumentsSave = async () => {
    setSubmitting(true);
    try {
      const response = CaseServices.addDefaultDocuments(documentList);
      console.log("add documents response:", response);
      window.alert("Document Added Successfully");
    } catch (error) {
      window.alert(error);
    }
    setSubmitting(false);
  };
  useEffect(() => {
    if (compositId) {
      getProcedureAllTierCost();
    }
    console.log("composit Id", compositId);
  }, [compositId]);
  return (
    <div className="fixed h-screen w-screen top-[54px] left-0 flex justify-center items-center bg-[#017C5780] z-10">
      <div className="relative h-[90%] w-[328px] xl:w-[50%] bg-white rounded-[16px] p-4  xl:p-8">
        <div className="absolute right-8 top-4 cursor-pointer">
          <img
            src={CloseIcon}
            alt="close-icon"
            onClick={() => setIsEditOpen(false)}
          />
        </div>
        <div className="text-2xl font-semibold mt-4 text-center">
          {type === "EDIT"
            ? "Edit Procedure"
            : step === 1
            ? "Add Procedure"
            : "Add Documents"}
        </div>
        {type === "ADD" && (
          <div className="text-[#797979] mt-4 text-center">Step {step}/2</div>
        )}
        {step === 1 && (
          <div className="h-[90%] overflow-y-scroll">
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-2 mt-4">
              <div>
                <div className="font-semibold mb-2">Speciality Name</div>

                <SuggestionBoxUtil
                  className="input-field"
                  api={CaseServices.defaultSpecialityList}
                  selectedValue={
                    specialityInputValue
                      ? specialityInputValue
                      : selectedSpeciality
                  }
                  name="speciality"
                  handleChange={handleAutoSuggestion}
                  setInputValue={setSpecialityInputValue}
                />
              </div>
              <div>
                <div className="font-semibold mb-2">Speciality ID</div>
                <input
                  type="text"
                  placeholder="Enter Speciality Id"
                  style={{ border: "1px solid #E4E4E4" }}
                  className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                  defaultValue={specialityId}
                  onChange={(e) => setSpecialityId(e.target.value)}
                />
              </div>
              <div>
                <div className="font-semibold mb-2">Procedure Name</div>
                <SuggestionBoxUtil
                  className="input-field"
                  searchParams={`${selectedSpeciality}`}
                  api={CaseServices.defaultProcedureNameList}
                  selectedValue={
                    procedureInputValue
                      ? procedureInputValue
                      : selectedProcedure
                  }
                  name="procedure"
                  handleChange={handleAutoSuggestion}
                  setInputValue={setProcedureInputValue}
                />
              </div>
              <div>
                <div className="font-semibold mb-2">Procedure ID</div>
                <input
                  type="text"
                  placeholder="Enter Procedure Id"
                  style={{ border: "1px solid #E4E4E4" }}
                  className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                  defaultValue={procedureId}
                  onChange={(e) => setProcedureId(e.target.value)}
                />
              </div>
            </div>
            <div
              style={{ border: "1px solid #E4E4E4" }}
              className="p-4 bg-[#F9F9F9] rounded-[8px] w-[100%] xl:w-[50%] mt-4"
            >
              <div className="font-semibold text-[#191919] mb-4">Implants</div>
              {implants &&
                implants.length > 0 &&
                implants?.map((implant, idx) => {
                  return (
                    <div key={idx} className="grid grid-cols-2 gap-2 mt-2">
                      <div>
                        <input
                          type="text"
                          name="name"
                          placeholder="Enter Name"
                          style={{ border: "1px solid #E4E4E4" }}
                          className="w-[100%] text-sm py-[10px] px-[12px] rounded-[8px]"
                          defaultValue={implant?.name}
                          onChange={(e) => handleChange(e, idx, "implants")}
                          required
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          name="price"
                          placeholder="Enter Price"
                          style={{ border: "1px solid #E4E4E4" }}
                          className="w-[100%] text-sm py-[10px] px-[12px] rounded-[8px]"
                          defaultValue={implant?.price}
                          onChange={(e) => handleChange(e, idx, "implants")}
                          required
                        />
                      </div>
                    </div>
                  );
                })}
              <div
                style={{ border: "1px solid #017C57" }}
                className="w-full text-xs text-[#017C57] font-semibold flex justify-center items-center gap-2 mt-4 rounded-full py-2 cursor-pointer"
                onClick={handleImplantsAddMore}
              >
                <img src={AddMore} alt="add-more-icon" />
                ADD MORE
              </div>
            </div>

            <div className="flex items-center gap-[6px] mt-6">
              <input
                type="checkbox"
                checked={isStratification}
                className="h-5"
                onChange={() => setIsStratification(!isStratification)}
              />{" "}
              <div className="text-sm text-[#797979]"> Stratification</div>
            </div>
            <div style={{ border: "1px solid #E4E4E4", marginTop: "40px" }} />
            {isStratification ? (
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mt-10">
                <div
                  style={{ border: "1px solid #E4E4E4" }}
                  className="p-4 rounded-[8px] bg-[#F9F9F9]"
                >
                  <div className="font-semibold text-[#191919] mb-4">
                    Tier 1 Costing
                  </div>
                  {Array.isArray(tier1Costing) &&
                    tier1Costing.length > 0 &&
                    tier1Costing?.map((tier, idx) => {
                      return (
                        <div key={idx} className="grid grid-cols-2 gap-2 mt-2">
                          <div>
                            <input
                              type="text"
                              name="name"
                              placeholder="Enter Name"
                              style={{ border: "1px solid #E4E4E4" }}
                              className="w-[100%] text-sm py-[10px] px-[12px] rounded-[8px]"
                              defaultValue={tier.name}
                              onChange={(e) => handleChange(e, idx, "tier1")}
                              required
                            />
                          </div>
                          <div>
                            <input
                              type="text"
                              name="price"
                              placeholder="Enter Price"
                              style={{ border: "1px solid #E4E4E4" }}
                              className="w-[100%] text-sm py-[10px] px-[12px] rounded-[8px]"
                              defaultValue={tier.price}
                              onChange={(e) => handleChange(e, idx, "tier1")}
                              required
                            />
                          </div>
                        </div>
                      );
                    })}
                  {/* <div className="grid grid-cols-2 gap-2 mt-2">
                    <div>
                      <input
                        type="text"
                        placeholder="Enter Name"
                        style={{ border: "1px solid #E4E4E4" }}
                        className="text-sm py-[10px] px-[12px] rounded-[8px]"
                        value={tier1Name}
                        onChange={(e) => handleInputValue(e, )}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter Price"
                        style={{ border: "1px solid #E4E4E4" }}
                        className="text-sm py-[10px] px-[12px] rounded-[8px]"
                        value={tier1Price}
                        onChange={(e) => setTier1Price(e.target.value)}
                      />
                    </div>
                  </div> */}
                  <button
                    style={{ border: "1px solid #017C57" }}
                    type="button"
                    className="w-full text-xs text-[#017C57] font-semibold flex justify-center items-center gap-2 mt-4 rounded-full py-2 cursor-pointer"
                    onClick={handleTier1AddMore}
                  >
                    <img src={AddMore} alt="add-more-icon" />
                    ADD MORE
                  </button>
                </div>
                <div
                  style={{ border: "1px solid #E4E4E4" }}
                  className="p-4 rounded-[8px] bg-[#F9F9F9]"
                >
                  <div className="font-semibold text-[#191919] mb-4">
                    Tier 2 Costing
                  </div>
                  {Array.isArray(tier2Costing) &&
                    tier2Costing.length > 0 &&
                    tier2Costing?.map((tier, idx) => {
                      return (
                        <div key={idx} className="grid grid-cols-2 gap-2 mt-2">
                          <div>
                            <input
                              type="text"
                              name="name"
                              placeholder="Enter Name"
                              style={{ border: "1px solid #E4E4E4" }}
                              className="w-[100%] text-sm py-[10px] px-[12px] rounded-[8px]"
                              defaultValue={tier.name}
                              onChange={(e) => handleChange(e, idx, "tier2")}
                              required
                            />
                          </div>
                          <div>
                            <input
                              type="text"
                              name="price"
                              placeholder="Enter Price"
                              style={{ border: "1px solid #E4E4E4" }}
                              className="w-[100%] text-sm py-[10px] px-[12px] rounded-[8px]"
                              defaultValue={tier.price}
                              onChange={(e) => handleChange(e, idx, "tier2")}
                              required
                            />
                          </div>
                        </div>
                      );
                    })}
                  {/* <div className="grid grid-cols-2 gap-2 mt-2">
                    <div>
                      <input
                        type="text"
                        placeholder="Enter Name"
                        value={tier2Name}
                        style={{ border: "1px solid #E4E4E4" }}
                        className="text-sm py-[10px] px-[12px] rounded-[8px]"
                        onChange={(e) => setTier2Name(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter Price"
                        value={tier2Price}
                        style={{ border: "1px solid #E4E4E4" }}
                        className="text-sm py-[10px] px-[12px] rounded-[8px]"
                        onChange={(e) => setTier2Price(e.target.value)}
                      />
                    </div>
                  </div> */}
                  <button
                    style={{ border: "1px solid #017C57" }}
                    type="button"
                    className="w-full text-xs text-[#017C57] font-semibold flex justify-center items-center gap-2 mt-4 rounded-full py-2 cursor-pointer"
                    onClick={handleTier2AddMore}
                  >
                    <img src={AddMore} alt="add-more-icon" />
                    ADD MORE
                  </button>
                </div>
                <div
                  style={{ border: "1px solid #E4E4E4" }}
                  className="p-4 rounded-[8px] bg-[#F9F9F9]"
                >
                  <div className="font-semibold text-[#191919] mb-4">
                    Tier 3 Costing
                  </div>
                  {Array.isArray(tier3Costing) &&
                    tier3Costing.length > 0 &&
                    tier3Costing?.map((tier, idx) => {
                      return (
                        <div key={idx} className="grid grid-cols-2 gap-2 mt-2">
                          <div>
                            <input
                              type="text"
                              name="name"
                              placeholder="Enter Name"
                              style={{ border: "1px solid #E4E4E4" }}
                              className="w-[100%] text-sm py-[10px] px-[12px] rounded-[8px]"
                              defaultValue={tier.name}
                              onChange={(e) => handleChange(e, idx, "tier3")}
                            />
                          </div>
                          <div>
                            <input
                              type="text"
                              name="price"
                              placeholder="Enter Price"
                              style={{ border: "1px solid #E4E4E4" }}
                              className="w-[100%] text-sm py-[10px] px-[12px] rounded-[8px]"
                              defaultValue={tier.price}
                              onChange={(e) => handleChange(e, idx, "tier3")}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {/* <div className="grid grid-cols-2 gap-2 mt-2">
                    <div>
                      <input
                        type="text"
                        placeholder="Enter Name"
                        value={tier3Name}
                        style={{ border: "1px solid #E4E4E4" }}
                        className="text-sm py-[10px] px-[12px] rounded-[8px]"
                        onChange={(e) => setTier3Name(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter Price"
                        value={tier3Price}
                        style={{ border: "1px solid #E4E4E4" }}
                        className="text-sm py-[10px] px-[12px] rounded-[8px]"
                        onChange={(e) => setTier3Price(e.target.value)}
                      />
                    </div>
                  </div> */}
                  <div
                    style={{ border: "1px solid #017C57" }}
                    className="w-full text-xs text-[#017C57] font-semibold flex justify-center items-center gap-2 mt-4 rounded-full py-2 cursor-pointer"
                    onClick={handleTier3AddMore}
                  >
                    <img src={AddMore} alt="add-more-icon" />
                    ADD MORE
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mt-10">
                <div
                  style={{ border: "1px solid #E4E4E4" }}
                  className="p-4 rounded-[8px] bg-[#F9F9F9]"
                >
                  <div className="font-semibold text-[#191919] mb-4">
                    Tier 1 Costing
                  </div>

                  <input
                    type="text"
                    placeholder="Enter Price"
                    defaultValue={tier1Costing}
                    style={{ border: "1px solid #E4E4E4" }}
                    className="w-full text-sm py-[10px] px-[12px] rounded-[8px]"
                    onChange={(e) => setTier1Costing(e.target.value)}
                  />
                </div>
                <div
                  style={{ border: "1px solid #E4E4E4" }}
                  className="p-4 rounded-[8px] bg-[#F9F9F9]"
                >
                  <div className="font-semibold text-[#191919] mb-4">
                    Tier 2 Costing
                  </div>

                  <input
                    type="text"
                    placeholder="Enter Price"
                    defaultValue={tier2Costing}
                    style={{ border: "1px solid #E4E4E4" }}
                    className="w-full text-sm py-[10px] px-[12px] rounded-[8px]"
                    onChange={(e) => setTier2Costing(e.target.value)}
                  />
                </div>
                <div
                  style={{ border: "1px solid #E4E4E4" }}
                  className="p-4 rounded-[8px] bg-[#F9F9F9]"
                >
                  <div className="font-semibold text-[#191919] mb-4">
                    Tier 3 Costing
                  </div>

                  <input
                    type="text"
                    placeholder="Enter Price"
                    defaultValue={tier3Costing}
                    style={{ border: "1px solid #E4E4E4" }}
                    className="w-full text-sm py-[10px] px-[12px] rounded-[8px]"
                    onChange={(e) => setTier3Costing(e.target.value)}
                  />
                </div>
              </div>
            )}

            {type === "EDIT" ? (
              <div className="flex justify-center mt-8">
                {submitting ? (
                  <CircularProgress />
                ) : (
                  <div
                    className="text-white text-xs font-semibold bg-[#017C57] rounded-full py-2 px-4 cursor-pointer "
                    onClick={handleSaveChanges}
                  >
                    SAVE CHANGES
                  </div>
                )}
              </div>
            ) : (
              <div className="flex justify-center mt-8">
                {submitting ? (
                  <CircularProgress />
                ) : (
                  <div
                    className="text-white text-xs font-semibold bg-[#017C57] rounded-full py-2 px-4 cursor-pointer "
                    onClick={() => {
                      setStep(2);
                      handleSaveChanges();
                    }}
                  >
                    SAVE AND NEXT
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {step === 2 && (
          <div className="h-[90%] overflow-y-scroll">
            {documentList &&
              documentList.length > 0 &&
              documentList?.map((document, idx) => {
                return (
                  <div
                    key={idx}
                    className=" bg-[#F9F9F9] p-4 rounded-[8px] mb-4"
                  >
                    <div className="flex justify-end">
                      <img
                        src={DeleteIcon}
                        alt="delete-icon"
                        className="cursor-pointer"
                        onClick={() => handleDocumentRemove(idx)}
                      />
                    </div>
                    <div className="grid grid-cols-1 xl:grid-cols-2 mt-4 gap-4 ">
                      <div>
                        <div className="font-semibold mb-2">Document Name</div>
                        <input
                          type="text"
                          placeholder="Enter Document Name"
                          style={{ border: "1px solid #E4E4E4" }}
                          className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                          defaultValue={document.documentName}
                          onChange={(e) => setDocumentName(e.target.value)}
                        />
                      </div>
                      <div>
                        <div className="font-semibold mb-2">Document Type</div>
                        <Select
                          name="documentType"
                          options={[
                            {
                              value: "ADMISSIONDOCUMENT",
                              label: "ADMISSIONDOCUMENT",
                            },
                            {
                              value: "DURINGTREATMENTDOCUMENT",
                              label: "DURINGTREATMENTDOCUMENT",
                            },

                            {
                              value: "ONDISCHARGEDOCUMENT",
                              label: "ONDISCHARGEDOCUMENT",
                            },
                          ]}
                          getOptionLabel={(options) => {
                            return options["label"];
                          }}
                          getOptionValue={(options) => {
                            return options["value"];
                          }}
                          value={document.documentType}
                          onChange={(item) => {
                            setDocumentType(item.value);
                          }}
                        />
                      </div>
                      <div>
                        <div className="font-semibold mb-2">
                          Speciality Name
                        </div>
                        <SuggestionBoxUtil
                          className="input-field"
                          api={CaseServices.defaultSpecialityList}
                          selectedValue={document.specialityName}
                          name="speciality"
                          handleChange={handleAutoSuggestion}
                          setInputValue={setSpecialityInputValue}
                        />
                      </div>
                      <div>
                        <div className="font-semibold mb-2">Speciality ID</div>
                        <input
                          type="text"
                          placeholder="Enter Speciality Id"
                          style={{ border: "1px solid #E4E4E4" }}
                          className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                          defaultValue={document.specialityId}
                          onChange={(e) => setSpecialityId(e.target.value)}
                        />
                      </div>
                      <div>
                        <div className="font-semibold mb-2">Procedure Name</div>
                        <SuggestionBoxUtil
                          className="input-field"
                          searchParams={`${selectedSpeciality}`}
                          api={CaseServices.defaultProcedureNameList}
                          selectedValue={document.procedureName}
                          name="procedure"
                          handleChange={handleAutoSuggestion}
                          setInputValue={setProcedureInputValue}
                        />
                      </div>
                      <div>
                        <div className="font-semibold mb-2">Procedure ID</div>
                        <input
                          type="text"
                          placeholder="Enter Procedure Id"
                          style={{ border: "1px solid #E4E4E4" }}
                          className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                          defaultValue={document.procedureId}
                          onChange={(e) => setProcedureId(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

            <div className=" bg-[#F9F9F9] p-4 rounded-[8px]">
              <div className="grid grid-cols-1 xl:grid-cols-2 mt-4 gap-4">
                <div>
                  <div className="font-semibold mb-2">Document Name</div>
                  {console.log("document name:", documentName)}
                  <input
                    type="text"
                    placeholder="Enter Document Name"
                    style={{ border: "1px solid #E4E4E4" }}
                    className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                    value={documentName}
                    onChange={(e) => setDocumentName(e.target.value)}
                  />
                </div>
                <div>
                  <div className="font-semibold mb-2">Document Type</div>
                  <Select
                    name="documentType"
                    options={[
                      {
                        value: "ADMISSIONDOCUMENT",
                        label: "ADMISSIONDOCUMENT",
                      },
                      {
                        value: "DURINGTREATMENTDOCUMENT",
                        label: "DURINGTREATMENTDOCUMENT",
                      },

                      {
                        value: "ONDISCHARGEDOCUMENT",
                        label: "ONDISCHARGEDOCUMENT",
                      },
                    ]}
                    defaultValue={documentType}
                    getOptionLabel={(options) => {
                      return options["label"];
                    }}
                    getOptionValue={(options) => {
                      return options["value"];
                    }}
                    onChange={(item) => {
                      setDocumentType(item.value);
                    }}
                  />
                </div>
                <div>
                  <div className="font-semibold mb-2">Speciality Name</div>

                  <SuggestionBoxUtil
                    className="input-field"
                    api={CaseServices.defaultSpecialityList}
                    selectedValue={
                      specialityInputValue
                        ? specialityInputValue
                        : selectedSpeciality
                    }
                    name="speciality"
                    handleChange={handleAutoSuggestion}
                    setInputValue={setSpecialityInputValue}
                  />
                </div>
                <div>
                  <div className="font-semibold mb-2">Speciality ID</div>
                  <input
                    type="text"
                    placeholder="Enter Speciality Id"
                    style={{ border: "1px solid #E4E4E4" }}
                    className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                    defaultValue={specialityId}
                    onChange={(e) => setSpecialityId(e.target.value)}
                  />
                </div>
                <div>
                  <div className="font-semibold mb-2">Procedure Name</div>
                  <SuggestionBoxUtil
                    className="input-field"
                    searchParams={`${selectedSpeciality}`}
                    api={CaseServices.defaultProcedureNameList}
                    selectedValue={
                      procedureInputValue
                        ? procedureInputValue
                        : selectedProcedure
                    }
                    name="procedure"
                    handleChange={handleAutoSuggestion}
                    setInputValue={setProcedureInputValue}
                  />
                </div>
                <div>
                  <div className="font-semibold mb-2">Procedure ID</div>
                  <input
                    type="text"
                    placeholder="Enter Procedure Id"
                    style={{ border: "1px solid #E4E4E4" }}
                    className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                    defaultValue={procedureId}
                    onChange={(e) => setProcedureId(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {console.log("document list:", documentList)}
            <div className="block xl:flex justify-center items-center gap-4 mt-8">
              <div
                style={{ border: "1px solid #017C57" }}
                className=" text-xs font-semibold text-[#017C57] flex justify-center items-center gap-2 bg-white rounded-full py-2 px-4 cursor-pointer "
                onClick={handleAddDocument}
              >
                <img src={AddMore} alt="add-more-icon" /> ADD DOCUMENT
              </div>
              {submitting ? (
                <CircularProgress />
              ) : (
                <div
                  className="text-white text-xs text-center font-semibold bg-[#017C57] rounded-full py-2 px-4 cursor-pointer mt-2 xl:mt-0"
                  onClick={handleDocumentsSave}
                >
                  SAVE CHANGES
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProcedure;
