import axios from "axios";
import { getAuthorizationToken } from "../config/user";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function getCallRecordings(caseId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/get-callings?caseId=${caseId}`,
    headers: getAuthorizationToken(),
  });
}

export const RecordingServices = {
  getCallRecordings,
};
