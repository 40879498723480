import React from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
const Report = () => {
  return (
    <div className="p-[32px] mt-[92px] w-full">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Reports
      </span>
      <text className="heading"> Reports</text>
      <hr
        style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
      />
      <div
        style={{ border: "1px solid #e4e4e4", marginTop: "24px" }}
        className="rounded-[16px] p-5"
      >
        <div className="flex items-center justify-between mb-6">
          <div className="text-2xl text-[#191919] font-semibold">Manage</div>
          <div>Search Container</div>
        </div>
        <hr
          style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
        />
        <div className="mt-10">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                }}
              >
                <span className="personal-details">Hospital Name</span>
                <div className="text-xs text-[#017C57] font-semibold bg-[#CDFAF4] rounded-full px-4 py-1 ">
                  999 CASES
                </div>
              </div>
            </AccordionSummary>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Report;
