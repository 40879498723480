import React, { useState, useEffect, useRef } from "react";

import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { CircularProgress, selectClasses } from "@mui/material";
import CustomPagination from "../../utils/paginationUtils";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { HospitalServices } from "../../services/HospitalServices";
import { CaseServices } from "../../services/CaseServices";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectedTab } from "../../redux/roleSlice";
import { logOut } from "../../config/user";
import callIcon from "../../assets/callIcon.svg";
import uploadIcon from "../../assets/uploadIcon.svg";
import PreinitiateModal from "../../dashboard/cases/preInitiateModal/PreinitiateModal";
import alertIconYellow from "../../assets/alertIconYellow.svg";
import "./InitiateAdmission.scss";

const InitiateAdmission = () => {
  const [filter, setFilter] = useState("");
  const [caseList, setCaseList] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCase, setSelectedCase] = useState(null);
  const [isPreinitiateOpen, setPreInitiate] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  let searchTimeRef = useRef();
  const dispatch = useDispatch();
  let limit = 5;

  const navigate = useNavigate();
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };
  const currentRole = useSelector((state) => state.currentRole.value);
  const calculatePrcentage = (uploaded, total) => {
    let percent = 0;
    if (total !== 0) {
      percent = (uploaded / total) * 100;
    }
    return percent;
  };

  const handleSearch = (e) => {
    //debouncing structure
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };
  const fetch = async () => {
    try {
      setIsLoading(true);
      const res = await HospitalServices.getHospitalCases(
        searchKey,
        page,
        limit,
        filter
      );
      setCaseList(res.data.caseList);
      setTotalPages(res?.data?.totalPages);
      setIsLoading(false);
      console.log("res", res);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 401) {
        logOut();
      } else if (
        currentRole.role.roleName === "TeamB" ||
        currentRole.role.roleName === "TeamA"
      ) {
        navigate("/dashboard/ActiveCases");
      } else {
        window.alert(error);
      }
    }
  };
  const handleInitiateAdmission = async (caseId) => {
    try {
      const res = await CaseServices.initiateAdmission(caseId);
      window.alert("Admission Initiated");
      await fetch();
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        console.log("error:", error);
        window.alert(error?.response?.data?.error);
      }
    }
  };
  useEffect(() => {
    fetch();
  }, [page, searchKey, filter]);

  useEffect(() => {
    if (selectedCase) {
      console.log("selected case:", selectedCase);
    }
  }, [selectedCase]);
  return (
    <div className="initiateadmission-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Manage Cases
      </span>
      <div className="heading">
        Manage Cases
        <div className="btn-container">
          <button
            className="outlined-btn"
            style={{ cursor: "pointer" }}
            onClick={() => handleFilterChange("ACTIVE")}
          >
            ACTIVE
          </button>
          <button
            className="outlined-btn"
            style={{ cursor: "pointer" }}
            onClick={() => handleFilterChange("INACTIVE")}
          >
            INACTIVE
          </button>

          {/* <button
            className="outlined-btn"
            style={{ cursor: "pointer" }}
            onClick={() => handleFilterChange("")}
          >
            INACTIVE
          </button> */}
        </div>
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          marginBottom: "20px",
        }}
      />

      <div className="Second-container">
        <div className="containers">
          <span className="total-cases">Total Cases</span>
          <div className="search-container">
            <SearchIcon className="search-icon" />
            <input
              type="text"
              className="search-box"
              placeholder="Search anything"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className=" flex flex-col items-end xl:flex-row xl:items-center ">
          <span
            style={{
              fontFamily: "Inter",
            }}
            className="w-[50%] text-sm text-left text-[#797979] xl:w-fit"
          >
            Sort by
          </span>
          <div className="block xl:flex ">
            <button className="select-all">SELECT ALL</button>
            <select className="case-status">
              <option value="">CASE STATUS</option>
            </select>
            <select className="case-status">
              <option value="">CASE STAGE</option>
            </select>
          </div>
        </div>
        <hr
          style={{
            width: "100%",
            backgroundColor: "rgba(228, 228, 228, 1)",
            marginTop: "30px",
          }}
        />
        {!isLoading ? (
          caseList?.length > 0 ? (
            <section className="mt-12 ">
              {caseList?.map((caseItem, idx) => {
                return (
                  <article
                    key={idx}
                    className={`${idx % 2 == 0 ? "bg-white" : "bg-[#f9f9f9]"}`}
                  >
                    <div className="px-2 2xl:pl-12 2xl:pr-7">
                      <div className=" py-4 2xl:p-4 ">
                        <div className="grid grid-cols-1  xl:grid-cols-7 justify-between items-center">
                          <div className="flex justify-start ">
                            <input type="checkbox" />
                          </div>

                          <div>
                            <p
                              className={`w-fit text-12px px-3 py-1 rounded-full capitalize ${
                                caseItem.masterstagestatuses[0].masterStageStatusText.toLowerCase() ===
                                "active"
                                  ? "bg-[#CDFAF4] text-[#017C57]"
                                  : "bg-[#FFF3C6] text-[#BCA03C]"
                              }`}
                            >
                              {
                                caseItem.masterstagestatuses[0]
                                  .masterStageStatusText
                              }
                            </p>
                          </div>
                          <div className="space-y-4">
                            <p className="text-[#191919] font-semibold">
                              Beneficiary no.
                            </p>
                            <p className="text-[#797979] text-[18px] ">
                              {caseItem.patient.beneficiaryNo
                                ? caseItem.patient.beneficiaryNo
                                : "--"}
                            </p>
                          </div>
                          <div className="space-y-4">
                            <p className="text-[#191919] font-semibold">
                              Patient Name
                            </p>
                            <p className="text-[#797979] text-[18px] ">
                              {caseItem.patient.patientName
                                ? caseItem.patient.patientName
                                : "--"}
                            </p>
                          </div>
                          <div className="space-y-4">
                            <p className="text-[#191919] font-semibold">
                              Registered Date
                            </p>
                            <p className="text-[#797979] text-[18px] ">
                              {caseItem.patient.registeredDate
                                ? `${new Date(
                                    caseItem.patient.registeredDate
                                  ).getDate()}/${
                                    new Date(
                                      caseItem.patient.registeredDate
                                    ).getMonth() + 1
                                  }/${new Date(
                                    caseItem.patient.registeredDate
                                  ).getFullYear()}`
                                : "--"}
                            </p>
                          </div>
                          <div className="space-y-4">
                            <p className="text-[#191919] font-semibold">
                              Stage
                            </p>
                            <p className="text-[#797979] text-[18px] max-w-[200px]">
                              {caseItem.submasterstagestatuses[0].hsaStage
                                ? caseItem.submasterstagestatuses[0].hsaStage
                                : "--"}
                            </p>
                          </div>
                          <div className="space-y-4">
                            <p className="text-[#191919] font-semibold">
                              Patient Phone
                            </p>
                            {console.log("caseItem:", caseItem)}
                            <p className="text-[#797979] text-[18px] ">
                              {caseItem.patient.phone
                                ? caseItem.patient.phone
                                : "--"}
                            </p>
                          </div>
                        </div>
                        <div className="grid grid-cols-1 xl:grid-cols-7 mt-4">
                          <div className="col-span-2"></div>
                          <div className="col-span-2 ">
                            <div className="w-fit flex 2xl:gap-2 bg-[#FFF3C6] rounded-full px-4 py-1 2xl:px-6 2xl:py-2">
                              <img src={alertIconYellow} alt="chat-icon" />
                              <button className="text-xs xl:text-[16px] text-[#BCA03C] bg-[#FFF3C6] border-0 ">
                                {" "}
                                {
                                  caseItem.submasterstagestatuses[0]
                                    .commandCenterStage
                                }
                              </button>
                            </div>
                          </div>

                          <div className="col-span-3 flex xl:justify-end mt-4 xl:mt-0">
                            <div className="flex items-center gap-4">
                              <div>
                                {caseItem.submasterstagestatuses[0].hsaStage ==
                                "Request to Admission" ? (
                                  currentRole.role.roleName.toLowerCase() !==
                                    "hospital" && (
                                    <button
                                      className="text-xs 2xl:text-sm font-semibold px-5 2xl:px-8 py-2 bg-[#017C57] text-white rounded-full border-0 cursor-pointer uppercase"
                                      onClick={() =>
                                        handleInitiateAdmission(caseItem.id)
                                      }
                                    >
                                      Initiate Admission
                                    </button>
                                  )
                                ) : caseItem.submasterstagestatuses[0]
                                    .hsaStage == "Admission Approved" ||
                                  caseItem.submasterstagestatuses[0].hsaStage ==
                                    "Enhancement Approved" ? (
                                  currentRole.role.roleName.toLowerCase() !==
                                    "hospital" && (
                                    <div className="flex gap-2">
                                      {" "}
                                      <button
                                        className="text-sm  font-semibold px-8 py-2 bg-[#017C57] text-white rounded-full border-0 cursor-pointer uppercase"
                                        onClick={() => {
                                          dispatch(selectedTab("ENHANCEMENT"));
                                          navigate("/Dashboard/EditCases", {
                                            state: { selectedCase: caseItem },
                                          });
                                        }}
                                      >
                                        {caseItem.submasterstagestatuses[0]
                                          .hsaStage == "Enhancement Approved"
                                          ? "Re-Initiate Enhancement"
                                          : "Initiate Enhancement"}
                                      </button>
                                      <button
                                        className="text-sm  font-semibold px-8 py-2 bg-[#017C57] text-white rounded-full border-0 cursor-pointer uppercase"
                                        onClick={() => {
                                          dispatch(selectedTab("DISCHARGE"));
                                          navigate("/Dashboard/EditCases", {
                                            state: { selectedCase: caseItem },
                                          });
                                        }}
                                      >
                                        Initiate Discharge
                                      </button>
                                    </div>
                                  )
                                ) : (
                                  <div style={{ width: "46px" }}>
                                    <CircularProgressbarWithChildren
                                      value={calculatePrcentage(
                                        caseItem?.totalDocuments -
                                          caseItem?.totalPendingDocuments,
                                        caseItem?.totalDocuments
                                      )} // value in percentage
                                      strokeWidth={13}
                                      styles={{
                                        path: {
                                          stroke: "#017C57",
                                          strokeLinecap: "round",
                                          transformOrigin: "center center",
                                        },
                                        trail: {
                                          stroke: "#CDFAF4",
                                          transformOrigin: "center center",
                                        },
                                      }}
                                    >
                                      {" "}
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "100%",
                                          fontSize: "10px",
                                          color: "#017C57",
                                          fontFamily: "inter, sans-serif",
                                          fontWeight: 500,
                                          // paddingBottom: "18px",
                                        }}
                                      >
                                        {caseItem.totalDocuments -
                                          caseItem?.totalPendingDocuments}
                                        /{caseItem?.totalDocuments}
                                      </div>
                                    </CircularProgressbarWithChildren>
                                  </div>
                                )}
                              </div>
                              <div className="flex gap-4">
                                <img
                                  src={callIcon}
                                  alt="call-icon"
                                  className="cursor-pointer"
                                />
                                {/* <div
                                  style={{ border: "1px solid #e4e4e4" }}
                                  className="relative px-3 py-1 rounded-[8px]"
                                >
                                  <img
                                    src={uploadIcon}
                                    alt="chat-icon"
                                    className="w-[20px] cursor-pointer"
                                    onClick={() => {
                                      setPreInitiate(true);
                                      setSelectedCase(caseItem);
                                    }}
                                  />
                                  <div className="absolute top-0 right-1 text-xs">
                                    {caseItem?.totalReferenceDocuments}
                                  </div>
                                </div> */}
                                <div
                                  style={{ border: "1px solid #017C57" }}
                                  className="flex  gap-2 px-2 py-2 rounded-full"
                                >
                                  <div className="text-[10px] h-[14px] w-[14px] text-[#FF1818] text-center bg-[#FFC8C8] rounded-full">
                                    {caseItem?.referenceDocumentsRejected}
                                  </div>
                                  <div className="text-[10px] h-[14px] w-[14px] text-[#017C57] text-center bg-[#CDFAF4] rounded-full">
                                    {caseItem?.totalReferenceDocuments}
                                  </div>
                                  <img
                                    src={uploadIcon}
                                    alt="upload-icon"
                                    className="w-[12px] cursor-pointer"
                                    onClick={() => {
                                      setPreInitiate(true);
                                      setSelectedCase(caseItem);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                );
              })}
            </section>
          ) : (
            <div className="flex items-center justify-center">
              <p className="text-xl font-bold">No Record Found</p>
            </div>
          )
        ) : (
          <div className="flex items-center justify-center mt-12 h-20">
            <CircularProgress />
          </div>
        )}
      </div>
      {isPreinitiateOpen && (
        <div>
          <PreinitiateModal
            selectedCase={selectedCase}
            isOpen={setPreInitiate}
          />
        </div>
      )}
      <div className="mt-8 flex justify-center">
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default InitiateAdmission;
