import React from 'react';
import background from "../../background2.jpg";
import "./Privacypolicy.scss";

const Greeting = () => {
  return (
    <div
      className="background-image"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="yellow-overlay"></div>
      <div className="content">
        <div className="wholecontent">
     
          <div style={{ display: 'flex', flexDirection: "column", gap: 150 }}>
            <text style={{ fontSize: 14, color: "#FFFFFF", fontFamily: "Inter", marginBottom: 10 }}>
              Settlemed Helps You Simplify Your Medical Claim Processing
            </text>
            <div className="texts">
              <h2>1,000+ Hospitals</h2>
              <h2>50,000+ Happy Patients</h2>
              <h2>75,000+ Claim Settlements</h2>
              <hr className="horizontal-line" />
            </div>
          </div>
          <div className="main2" > 
            <div style={{width:"434px", height:"34px", gap:"8px"}}>
            <h1 style={{fontFamily:"Inter", fontWeight:"600",size:"24px", color:"rgba(25, 25, 25, 1)"}}>Terms and condition</h1> 
            <hr style={{width:"434px", border:"1px solid rgba(228, 228, 228, 1)"}} />
            <text style={{color:'rgba(121, 121, 121, 1)'}}>

Welcome to https://www.settlemed.in/ (hereinafter referred to as the “Platform”, “Website”, “Site”) owned and operated by Settlemed (“We”, “Us”, “Our”) with its registered office located at, 11th Floor, Sovereign Corporate Tower, Sector 136, Noida Expressway, Noida, Uttar Pradesh.

This Platform is offered to users (hereinafter referred to as “You” or “Your”) conditioned on user’s acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). These Terms and Conditions govern your use of our Platform.
Please read carefully these Terms and Conditions and our Privacy Policy, which may be found at [LINK TO PRIVACY POLICY], and which is incorporated by reference into these Terms.

Use of this Platform constitutes your consent to, and agreement to, abide by the most current version of these Terms and Conditions. We may at any time revise these Terms and Conditions by updating the Terms on our platform. You agree to be bound by subsequent revisions and agree to review the Terms periodically for changes to the Terms and Conditions. The most up to date version of the Terms will always be available for your review under the "Terms and Conditions" link that appears at the bottom of the platform.

PLEASE READ THESE TERMS CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. BY USING THIS PLATFORM OR SERVICE INDICATES YOUR UNDERSTANDING OF THESE TERMS AND ACCEPTANCE, OF THE DISCLAIMER NOTICE

DEFINITIONS:

"Agreement" means the Terms and Conditions as detailed herein including all Exhibits, privacy policy; all other policies published on the site and will include the references to this agreement as amended, negated, supplemented, varied or replaced from time to time.

“Account” shall mean the accounts created by the Users on our Platform in order to use our Services and requires information such as name, address, contact number etc.

"Content" means text, graphics, images, software, audio, video, information or other materials.  






“Our Content” shall mean all the Content that our Platform makes available through the Som a third party. 



"Collective Content" means User Content and our platform content.  

2. INTERPRETATION:
2.1. The official language of these terms shall be English.
2.2. The headings and sub-headings are merely for convenience purpose and shall not be used for interpretation.
3. ELIGIBILITY:
3.1. You may use the Platform only if you are at least eighteen (18) years of age and can form a binding contract with us, and only in compliance with this Agreement and all applicable local, state, national, and international laws, rules and regulations.
3.2. Any use or access to the Platform by anyone under 18 is strictly prohibited and in violation of this Agreement. Any person under the age of eighteen (18) years accessing the Platform should do so only under parental guidance. Our Platform reserves the right to terminate your Subscription and refuse to provide you with access to the Platform if we discover that you are under the age of 18 years. The Platform is not available to any Users previously removed from the Service by us, unless we provide such Users with specific written authorization to re-use the Service. Unauthorized Users are strictly prohibited from accessing or attempting to access, directly or indirectly, the Platform. Any such unauthorized use is strictly forbidden and shall constitute a violation of applicable state and local laws.
3.3. By using our Platform and its services, you represent and warrant that you are at least 18 years old and that you have the right, authority and capacity to enter into and abide by the Terms and Conditions of this Agreement.
3.4. If you use our Platform on behalf of a company, organization, or other entity, then (a) "you" includes you and that entity, and (b) you represent and warrant that you are an authorized representative of the entity with the authority to bind the entity to this Agreement, and that you agree to this Agreement on the entity's behalf.
3.5. We may, in our sole discretion, refuse to offer access to or use of the Platform to any person or entity and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the Platform is revoked in such jurisdictions
3.6. Except where additional Terms and Conditions are provided which are services specific, these Terms and Conditions supersede all previous representations, understandings, or agreements and shall prevail notwithstanding any variance with any other terms.
4. SERVICES
4.1. We specialize in developing comprehensive plans to effectively manage, and resolve potential challenges before they arise, eliminate or limit setbacks, ensuring a smoother processing and minimizing financial uncertainties for your medical claims.
4.2. Our Services utilize Artificial Intelligence technology to automate and streamline the Mediclaim Processing and Claims Settlement processes such as:
• Automated data Extraction
• Customizable Workflow
• Claims validation and Adjudication
• Tracking Claim status and providing updates to Hospitals.
• Medical Coding and Assignment of Appropriate codes in accordance with ICD-10 coding standards
• Electronic submission of claims to the ABPMJAY TMS platform and process the claims, subject to AB-PMJAY Terms and Conditions.
• Integration of AB-PMJAY TMS platform
• Reporting and Analytics Tools
• Data entry and validation of claim information.
• Pre-submission claim scrubbing to identify errors and inconsistencies.
• Deployment of 1 intelligent digital Medco.
• Activation of 1 admin app.
• Up to 50 sub-admin apps.
• Supervision of Settlemed doctors.
• Access to claim denial predictive solution.
 
5. PAYMENTS
5.1. You are required to make the payment towards the services opted. Before proceeding with the payment please ensure to provide accurate details such as name, email and other significant information provided in the payment page.
5.2. We will start our services within 15 days from the date of Payment. You may choose between two subscription options (Yearly and Quarterly) depending on your need and preferences.
5.3. We offer a variety of secure and convenient payment methods through our third party payment gateway to ensure a seamless and efficient payment process.
6. REFUNDS AND CANCELLATIONS
6.1. You shall be eligible to raise a refund request after 60 days of our service. However, the refund request window shall be open for a period of only 7 days following the completion of the 60-day tenure. Refund requests made outside of this 7-day window shall not be considered.
6.2. We also offer 2 months free trial period in first quarter to evaluate our services. If, during that trial period, you are not satisfied with our services, you may inform us to cancel the subscription.
6.3. Upon receipt of Cancellation notice within the trial period, we will refund the entire subscription amount paid by you without any deduction for 1 month. Upon cancellation, Users shall refrain from availing services from Settlemed for a duration of one year. No refunds shall be provided for requests received after the trial period has expired.
7. ACKNOWLEDGEMENTS/REPRESENTATIONS AND WARRANTIES BY USERS:
7.1. We reserves the right to initiate civil and/or criminal proceedings against a user who, files an invalid and/or false claim or provides false, incomplete, or misleading information. In addition to the legal proceedings as aforesaid, we may at our sole discretion suspend, block, restrict, cancel the user id of such user and/or disqualify that user from using our Platform. Any person who knowingly and with an intention to injure, defraud or deceive, files a Fraudulent claim or complaint containing false, incomplete, or misleading information shall be guilty of a criminal offence and will be prosecuted to the fullest extent of the law.
7.2. You acknowledge and undertake that you are accessing the services on the platform and transacting at your own risk and are using your best and prudent judgment before making any purchases through the platform.
7.3. You shall not tamper, pilfer or misuse the platform Device(s) or allow, induce or assist a third party to indulge in such activity.
7.4. We bear no responsibility and liability for delays and losses suffered by you or caused to you as a consequence of any service provided to you.
8. YOU AGREE AND CONFIRM:
8.1. That you will use the services provided through our Platform, its affiliates and contracted companies, for lawful purposes only and comply with all data protection applicable laws and regulations while using the platform.
8.2. You will provide authentic and true information in all instances where such information is requested of you. We reserve the right to confirm and validate the information and other details provided by you at any point of time. If upon confirmation details provided by you are found not to be true (wholly or partly), we have the right in our sole discretion to reject the subscription and debar you from using the Services of our Platform and / or other affiliated platforms without prior intimation whatsoever.
8.3. That you are accessing the services available on this Platform and transacting at your sole risk and are using your best and prudent judgment before entering into any dealings through this platform.
8.4. You agree that you will not:
i. Alter, damage or delete any Content or other communications that are not your own Content or to otherwise interfere with the ability of others to access our platform;
ii. Claim a relationship with or to speak for any business, association, institution or other organization for which you are not authorized to claim such a relationship;
iii. Violate any operating rule, policy or guideline of our Internet access provider or online service.
iv. We reserve the right to charge you for the services in near future if there is a significant change in our business model. We shall inform you about any such change by amending our User Agreement. You agree that Company reserves the sole right to change its business model and charge for the services being provided herein.
9. YOU MAY NOT USE THE PLATFORM FOR ANY OF THE FOLLOWING PURPOSES:
9.1. Disseminating any unlawful, harassing, libelous, abusive, threatening, harmful, vulgar, obscene, or otherwise objectionable material.
9.2. Transmitting material that encourages conduct that constitutes a criminal offense results in civil liability or otherwise breaches any relevant laws, regulations or code of practice.
9.3. You shall not create liability for us or cause us to lose (in whole or in part) the services of our internet service provider ("ISPs") or other suppliers;
9.4. You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the platform or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the platform. We reserve our right to bar any such activity.
9.5. You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the platform or to any server, computer, network, or to any of the services offered on or through the platform, by hacking, password "mining" or any other illegitimate means.
9.6. You shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication measures on the platform or any network connected to the platform. You may not reverse look-up, trace or seek to trace any information of any other User or visitor to Platform, or any other customer, including any account on the Platform not owned by You, to its source, or exploit the Platform or any service or information made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than Your own information, as provided for by the Platform.
9.7. You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about Us or the brand name or domain name used by Us or otherwise engage in any conduct or action that might tarnish the image or reputation, of our Platform or otherwise tarnish or dilute any of our trade or service marks, trade name and/or goodwill associated with such trade or service marks, trade name as may be owned or used by us. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Platform or our systems or networks, or any systems or networks connected to us.
9.8. You agree not to use any device, Platform or routine to interfere or attempt to interfere with the proper working of the Platform or any transaction being conducted on the platform, or with any other person's use of the platform.
9.9. You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to us on or through the platform or any service offered on or through the Platform. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity.
9.10. You may not use the Platform or any content for any purpose that is unlawful or prohibited by these Terms, or to solicit the performance of any illegal activity or other activity which infringes the rights of our platform and / or others.
9.11. Interfering with any other person's use or enjoyment of the platform.
9.12. Breaching any applicable laws;
9.13. Interfering or disrupting networks or web Websites connected to the Platform.
9.14. Making, transmitting or storing electronic copies of materials protected by copyright without the permission of the Company.
9.15. Without limiting other remedies, we may, in our sole discretion, limit, suspend, or terminate our services and user accounts, prohibit access to our platform, services, and their content, delay or remove hosted content, and take technical and legal steps to keep users from using our platform or services, if we think that they are creating problems or possible legal liabilities, infringing the intellectual property rights of third parties, or acting inconsistently with the letter or spirit of our policies. We also reserve the right to cancel unconfirmed accounts or accounts that have been inactive for a period of months, or to modify or discontinue our platform or services. We shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services).
9.16. Further we prohibit the transmission, distribution or posting of any matter which discloses personal or private information concerning any person or entity, including without limitation phone number(s) or addresses, credit, debit cards, calling card, User account numbers/ passwords or similar financial information, and home phone numbers or addresses. Even though all of this is strictly prohibited, there is a small chance that you might become exposed to such items and you further waive your right to any damages (from any party) related to such exposure.
10. OWNERSHIP:
10.1. All right, title, and interest in and to the platform and its associated services are and will remain the exclusive property of Settlemed. The platform is protected by copyright, trademark, and other laws of India. Nothing in these Terms of Use gives you a right to use the name of the platform’s trademark or logo, or any other trademarks, logos, domain names, or other distinctive brand features relating to the platform or located on the platform.
11. COPYRIGHT & TRADEMARK:
11.1. Settlemed and our licensors expressly reserve all intellectual property rights in all text, programs, products, processes, technology, content and other materials, which appear on this platform. Access to this platform does not confer and shall not be considered as conferring upon anyone any license under any of our or any third party's intellectual property rights. All rights, including copyright, in this platform are owned by or licensed to us or third party suppliers. Any use of this platform or its contents, including copying or storing it or them in whole or part, other than for your own personal, non-commercial use is prohibited without the permission of us. You cannot modify, distribute or re-post anything on this platform for any purpose.
11.2. Content and logos and all related product and service and our slogans are the trademarks or service marks of the Settlemed. All other marks are the property of their respective companies. No trademark or service mark license is granted in connection with the materials contained on this platform. Access to this platform does not authorize anyone to use any name, logo or mark in any manner.
11.3. All materials, including images, text, illustrations, designs, icons, photographs, programs, music clips or downloads, video clips and written and other materials that are part of this platform (collectively, the "Contents") are intended solely for personal, non-commercial use. You may download or copy the Contents and other downloadable materials displayed on the platform for your personal use only. No right, title or interest in any downloaded materials or software is transferred to you as a result of any such downloading or copying. You may not reproduce (except as noted above), publish, transmit, distribute, display, modify, create derivative works from, sell or participate in any sale of or exploit in any way, in whole or in part, any of the Contents, the platform or any related services. All services used on this platform is the property of Settlemed or its suppliers and protected by Copyright Laws of India. Any other use, including the reproduction, modification, distribution, transmission, republication, display, or performance, of the Contents on this platform is strictly prohibited. Unless otherwise noted, all Contents are copyrights, trademarks and/or other intellectual property owned, controlled or licensed by our platform, one of its affiliates or by third parties who have licensed their materials to us and are protected by Copyright Laws of India. The compilation (meaning the collection, arrangement, and assembly) of all Contents on this platform is the exclusive property of our Company and is also protected by Copyright Laws of India.
12. INDEMNITY:
12.1. Users agree to defend, indemnify and hold harmless our Platform, its employees, directors, officers, agents and their successors and assigns from and against any and all claims, liabilities, damages, losses, costs and expenses, including attorney's fees, caused by or arising out of claims based upon your actions or inactions, which may result in any loss or liability to our Platform or any third party including but not limited to breach of any warranties, representations or undertakings or in relation to the non-fulfillment of any of your obligations under this User Agreement or arising out of your violation of any applicable laws, regulations including but not limited to Intellectual Property Rights, payment of statutory dues and taxes, claim of libel, defamation, violation of rights of privacy or publicity, loss of service by other subscribers and infringement of intellectual property or other rights. This clause shall survive the expiry or termination of this User Agreement.
13. TERMINATION/SUSPENSION OF SUBSCRIPTION
13.1. We may, at any time and without notice, suspend, cancel, or terminate your right to use the Platform (or any portion of the Platform). In the event of suspension, cancellation, or termination, you are no longer authorized to access the part of the Platform affected by such suspension, cancellation, or termination. In the event of any suspension, cancellation, or termination, the restrictions imposed on you with respect to material downloaded from the Platform, and the disclaimers and limitations of liabilities set forth in the Agreement, shall survive.
13.2. Without limiting the foregoing, we may close, suspend or limit your access to your Account:
i. if we determine that you have breached, or are acting in breach of, this User Agreement;
ii. if we determine that you have breached legal liabilities (actual or potential), including infringing someone else's Intellectual Property Rights;
iii. if we determine that you have engaged, or are engaging, in fraudulent, or illegal activities;
iv. you do not respond to account verification requests;
v. to manage any risk of loss to us, a User, or any other person; or
vi. For other similar reasons.​
13.3. If we close your Account due to your breach of this Agreement, you may also become liable for fees in an amount as ascertained by the Platform.
13.4. In the event that we close your Account, you will have no claim whatsoever against us in respect of any such suspension or termination of your Account.
14. GOVERNING LAW AND JURISDICTION:
14.1. This Agreement shall be governed by and construed in accordance with the laws of India without regard to its choice of law principles.  
14.2. The parties consent to exclusive jurisdiction and venue in the courts sitting in New Delhi, India.
15. RESOLUTION OF DISPUTES:
15.1. In the interest of resolving disputes between you and us in the most expedient and cost effective manner, you and we agree that any and all disputes arising in connection with the Terms shall be resolved by binding arbitration. Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than in court, and can be subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. Our agreement to arbitrate disputes includes, but is not limited to all claims arising out of or relating to any aspect of the Terms, whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory, and regardless of whether the claims arise during or after the termination of the Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THE TERMS, YOU AND WE ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
15.2. The venue for arbitration shall be Uttar Pradesh, India
15.3. The language used in Arbitration shall be English and the award of the arbitration shall be binding on both, you and us.
16. DISCLAIMER:
16.1. The Platform is provided without any warranties or guarantees and in an "As Is" condition. You must bear the risks associated with the use of the Platform.
16.2. We make no warranty that the Services or Platform will meet your requirements or that the Services or your access to the Platform will be uninterrupted, timely, accurate or reliable; nor do we make any warranty as to any information that may be obtained through the Services or platform. In case there is any defect in any Platform being used for the provision of the Services, we do not make any warranty that defects in such Platform will be corrected. You understand and agree that any material and/or data downloaded or otherwise obtained through use of the Services or platform is done at your own discretion and risk and you will be solely responsible for any damage to your computer system or loss of data that results from the download of such material or data.
16.3. User understands and agrees that any information or service obtained through the platform is done at user's own discretion and risk and that user will be solely responsible for any damage resulting from any transaction.
17. SECURITY:
17.1. We have employed highest possible security measures to protect your data which is stored with us. While we take all possible measures, you must immediately notify us at Hi@settlemed.in upon becoming aware of any unauthorized access, any illegal online activity or any other security breach pertaining to the platform, your Account or our Services and do everything under your control to mitigate the unauthorized access or security breach (including providing us the evidence and notifying appropriate authorities). You are solely responsible for securing your password. We will not be liable for any loss or damage arising from unauthorized access of your account resulting from your failure to secure your password.
18. NOTICES:
18.1. Any notices must be given by postal mail to us at;
​​      Attn: Hi@settlemed.in or info@settlemed.in

18.2. In your case, we will send you any notice at your provided email address (either during the subscription process or when your email address changes). Notice shall be deemed given 24 hours after email is sent, unless the sending party is notified that the email address is invalid. Alternatively, we may give you notice by certified mail, postage prepaid and return receipt requested, to the address provided to us. In such case, notice shall be deemed given three days after the date of mailing.
19. OUR SERVICE AND GUARANTEES:
19.1. Our platform reserves the right to modify or terminate the Platform service for any reason, without notice, at any time. We also reserve the right to sell, alter, transfer or delegate our rights under this agreement to anyone without any prior notice to you. Our Platform does not guarantee continuous, uninterrupted access to the Platform, and operation of the platform may be interfered with by numerous factors outside our control.
20. NO WAIVER IMPLIED:
20.1. The failure of us to enforce at any time any of the provisions of these of Agreement, or the failure to require at any time performance by you of any of the provisions of these provisions, shall in no way be construed to be a present or future waiver of such provisions, nor in any way affect our right to enforce each and every such provision thereafter. The express waiver by us of any provision, condition or requirement of these provisions shall not constitute a waiver of any future obligation to comply with such provision, condition or requirement.
21. SEVERABILITY:
21.1. Each Term shall be deemed to be severable. If any Term or portion thereof is found to be invalid or unenforceable, such invalidity or unenforceability shall in no way effect the validity or enforceability of any other Term.
22. ASSIGNMENT:
22.1. You will not assign any rights or delegate any obligations under these Terms, in whole or in part, by operation of law or otherwise, without obtaining our prior written consent, which may be withheld in our sole discretion.
22.2. We may assign our rights and delegate any of our obligations under these Terms, in whole or in part, without your consent. Any assignment or delegation in violation of the foregoing will be null and void. These Terms will be binding and inure to the benefit of each party’s permitted successors and assigns.
23. FORCE MAJEURE:
23.1. In no event we shall not be responsible or liable for any failure or delay in the performance of obligations hereunder in these terms arising out of or caused by, directly or indirectly, forces beyond its control, including, without limitation, strikes, work stoppages, accidents, acts of war or terrorism, civil or military disturbances, nuclear or natural catastrophes or acts of God, and interruptions, loss or malfunctions of utilities, communications, computer hacking, malicious damage or computer (software and hardware) services;
24. MODIFICATION:
24.1. The Terms and Conditions cannot be modified on an individual basis by any person affiliated, or claiming affiliation, with us. Nothing in this section will prevent us from modifying the terms of these Terms and Conditions and posting such modifications on our Platform. We reserve the right, in our sole and exclusive discretion, to revise these Terms and Conditions at any time. All revisions shall be posted on this page. Since you are bound by all revisions made by us, you should review this page each time you connect to our Platform. It is important that you fully read and understand the Terms and Conditions you are agreeing to be bound by, when you use this platform.
25. COMMUNICATIONS:
25.1. Our team may send you information about offers, notices, letters and other communication to your email. You can ask us to refrain from sending you offers or promotional offers by sending us an email at Hi@settlemed.in or by clicking the unsubscribe link in our emails sent to you.
25.2. You consent to receive notices and information from us in respect of the Platform and Services by electronic communication. You may withdraw this consent at any time, but if you do so we may choose to suspend or close your Account.
25.3. By subscribing, you consent to electronically receive and access, via email, all records and notices for the services provided to you under this Agreement that we would otherwise be required to provide to you in paper form.
26. ENTIRE AGREEMENT:
26.1. The Agreement, in connection with the other obligations, policies and rules detailed in writing on the platform, constitute the entire agreement between you and Settlemed.
<button>Agree and continue</button>
 
 

 
            </text>
            </div>
            </div>
         
        </div>
      </div>
    </div>
   
  );
};

export default Greeting;
