import React, { useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import { object, string } from "yup";
import "./AddHospitalDetails.scss";
import { HospitalServices } from "../../../services/HospitalServices";
import { CaseServices } from "../../../services/CaseServices";
import { logOut } from "../../../config/user";
import SuggestionBoxUtil from "../../../utils/SuggestionBoxUtil";

const AddHospitalDetails = ({
  setValue,
  sethospitalid,
  functionalityType,
  hospitalDetails,
  refreshPage,
  caseId,
}) => {
  const [hospitalInput, setHospitalInput] = useState(null);
  const formikRef = useRef();
  const initialValues =
    functionalityType === "ADD"
      ? {
          huid: "",
          name: "",
          city: "",
        }
      : {
          huid: hospitalDetails?.id,
          name: hospitalDetails?.name,
          city: hospitalDetails?.city,
        };

  const errorValidation = object({
    huid: string().required("Required Field"),
    name: string().required("Required Field"),
    city: string().required("Required Field"),
  });

  const handleAutoSuggestion = (selected, e) => {
    formikRef.current.setValues({
      huid: selected.id,
      name: selected.value,
      city: selected.city,
    });
  };

  const handleSubmit = async (values) => {
    try {
      if (functionalityType === "EDIT") {
        await CaseServices.updateCase({ hospitalId: values.huid }, caseId);
        await refreshPage();
      }
      sethospitalid(values.huid);
      setValue("PATIENT DETAILS");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={errorValidation}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {({ errors, touched, values }) => (
        <Form className="add-hospital-container">
          <div className="heading">
            {functionalityType === "EDIT" ? "Edit " : ""}Hospital Details
          </div>
          <hr style={{ color: "#E4E4E4", marginTop: "24px", width: "100%" }} />
          <div className="case-form-container1">
            <label className="label1">
              <div className="text-content">Hospital Name</div>
              <SuggestionBoxUtil
                className="input-field"
                api={HospitalServices.gethospitals}
                selectedValue={values.name}
                name="name"
                handleChange={handleAutoSuggestion}
                sorted={true}
                setInputValue={setHospitalInput}
              />
              {touched.name && errors.name && (
                <div className="field-error">{errors.name}</div>
              )}
            </label>
            <label className="label1">
              <div className="text-content">Hospital City</div>
              <Field
                className="input-field"
                name="city"
                type="text"
                disabled={true}
              />
              {touched.city && errors.city && (
                <div className="field-error">{errors.city}</div>
              )}
            </label>
            <label className="label1">
              <div className="text-content">HUID</div>
              <Field
                className="input-field"
                name="huid"
                type="text"
                disabled={true}
              />
              {touched.huid && errors.huid && (
                <div className="field-error">{errors.huid}</div>
              )}
            </label>
          </div>
          <div className="button-box">
            <button type="submit" className="fill-btn">
              SAVE AND PROCEED
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddHospitalDetails;
