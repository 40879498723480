import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { number, object, string, date, array } from "yup";
import { CaseServices } from "../../../services/CaseServices";
import AddCaseProcedure from "./AddCaseProcedure";
import { useSelector } from "react-redux";
import "./AddCaseDetails.scss";
import PersonalHistoryModal from "../casedetails/PersonalHistoryModal";
import FamilyHistoryModal from "../casedetails/FamilyHistoryModal";
import GeneralFindingsModal from "../casedetails/GeneralFindingsModal";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { logOut } from "../../../config/user";
import CaseDetails from "../casedetails/caseDetails";
import { PatientServices } from "../../../services/PatientServices";
import { CircularProgress } from "@mui/material";
const AddCaseDetails = ({
  setValue,
  setCaseId,
  patientId,
  functionalityType,
  caseDetails,
  refreshPage,
  hospitalid,
  patientDetails,
  setPatientDetails,
  setPatientId,
}) => {
  const [updated, setUpdated] = useState(false); // to refresh the page
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentRole = useSelector((state) => state.currentRole.value);
  const addProcedure = (push) => {
    push({
      speciality: "",
      procedureId: "",
      ichiCode: "",
      stratification: "",
      implants: "",
      doctorName: "",
      specialityId: "",
      compositId: "",
    });
  };
  let initialValues;

  {
    functionalityType === "EDIT"
      ? (initialValues = {
          primaryDiagnosis: caseDetails?.primaryDiagnosis,
          secondaryDiagnosis: caseDetails?.secondaryDiagnosis,
          admissionType: caseDetails?.admissionType,
          admissionDate:
            caseDetails?.admissionDate &&
            caseDetails?.admissionDate.split("T")[0],
          fir: caseDetails.fir,
          policeStationName: caseDetails.policeStationName,
          packageCost: caseDetails.packageCost,
          totalPackageCost: caseDetails.totalPackageCost,
          caseProcedures: caseDetails?.caseprocedures,
        })
      : (initialValues = {
          primaryDiagnosis: "",
          secondaryDiagnosis: "",
          admissionType: "",
          admissionDate: "",
          fir: "",
          policeStationName: "",
          // packageCost: "",
          totalPackageCost: "",
          caseProcedures: [
            {
              speciality: "",
              specialityId: "",
              procedure: "",
              procedureId: "",
              CompositId: "",
              procedureType: "",
              compositId: "",
              ichiCode: "",
              tierCost: "",
              tier: "",
              stratification: "",
              implants: "",
              doctorName: "",
              doctorId: "",
              packageCost: "",
            },
          ],
        });
  }

  const errorValidation = object({
    primaryDiagnosis: string().required("Required Field"),
    // secondaryDiagnosis: string().required("Required Field"),
    admissionType: string().required("Required Field"),
    admissionDate: date().required("Required Field"),
    // fir: string().required("Required Field"),
    // policeStationName: string().required("Required Field"),
    // packageCost: number().required("Required Field"),
    totalPackageCost: number().required("Required Field"),
    caseProcedures: array().of(
      object({
        speciality: string().required("Required Field"),
        procedure: string().required("Required Field"),
        // ichiCode: string().required("Required Field"),
        // stratification: string().required("Required Field"),
        // implants: string().required("Required Field"),
        doctorName: string().required("Required Field"),
        packageCost: number().required("Required Field"),
      })
    ),
  });

  const handleSubmit = async (values) => {
    // console.log(values)
    setIsSubmitting(true);
    try {
      let caseRes = await CaseServices.addNewCase({
        ...values,
        patientId: patientId,
        hospitalId: hospitalid,
        status: "CASEADDED",
      });
      setCaseId(caseRes?.data?.caseId);
      await CaseServices.addCaseProcedure(
        values.caseProcedures,
        caseRes?.data?.caseId
      );
      window.alert("Case Details saved");
      setValue("MAP DOCUMENTS");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  const handleUpdate = async (values) => {
    // console.log(values);.
    setIsSubmitting(true);
    try {
      await CaseServices.updateCase(values, caseDetails?.id);
      await CaseServices.updateCaseProcedures(
        values.caseProcedures,
        caseDetails?.id
      );
      await refreshPage();
      window.alert("Case Details updated");
      setValue("MAP DOCUMENTS");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  async function getPatientDetails(patientId) {
    try {
      let res = await PatientServices.getPatientDetails(patientId);
      if (res?.data?.patient) setPatientDetails(res?.data?.patient);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  }
  // console.log(values)
  useEffect(() => {
    if (functionalityType === "EDIT") getPatientDetails(patientId);
  }, [updated]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={errorValidation}
      onSubmit={functionalityType === "EDIT" ? handleUpdate : handleSubmit}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form className="add-case-container">
          <div className="heading">
            {functionalityType === "EDIT" ? "Edit " : ""}Case Details
          </div>
          <FieldArray name="caseProcedures">
            {({ insert, remove, push }) => (
              <div>
                {values &&
                  values.caseProcedures &&
                  values.caseProcedures.map((procdure, idx) => {
                    return (
                      <div key={idx}>
                        <hr
                          style={{
                            color: "#E4E4E4",
                            marginTop: "40px",
                            width: "100%",
                          }}
                        />
                        <AddCaseProcedure
                          errors={errors}
                          touched={touched}
                          index={idx}
                          procdure={procdure}
                          setFieldValue={setFieldValue}
                          patientDetails={patientDetails}
                          values={values}
                        />
                      </div>
                    );
                  })}
                <div className="button-box">
                  <button
                    type="button"
                    className="fill-btn"
                    style={{
                      width: "max-content",
                      paddingInline: "32px",
                      cursor: "pointer",
                    }}
                    onClick={() => addProcedure(push)}
                  >
                    <AddOutlinedIcon style={{ height: "14px" }} />
                    ADD PROCEDURE {values?.caseProcedures.length + 1}
                  </button>
                </div>
              </div>
            )}
          </FieldArray>

          <hr style={{ color: "#E4E4E4", marginTop: "40px", width: "100%" }} />
          <div className="block xl:flex mt-[40px] gap-[8px]">
            <GeneralFindingsModal
              patientId={patientId}
              patientDetails={patientDetails}
              setUpdated={setUpdated}
              updated={updated}
              functionalityType={functionalityType}
            />
            <PersonalHistoryModal
              patientId={patientId}
              patientDetails={patientDetails}
              setUpdated={setUpdated}
              updated={updated}
              functionalityType={functionalityType}
            />
            <FamilyHistoryModal
              patientId={patientId}
              patientDetails={patientDetails}
              setUpdated={setUpdated}
              updated={updated}
              functionalityType={functionalityType}
            />
          </div>
          <div className="case-form-container1">
            <label className="label1">
              <div className="text-content">Primary Diagnosis</div>
              <Field
                className="input-field"
                name="primaryDiagnosis"
                placeholder="Primary Diagnosis"
              />
              {touched.primaryDiagnosis && errors.primaryDiagnosis && (
                <div className="field-error">{errors.primaryDiagnosis}</div>
              )}
            </label>
            <label className="label1">
              <div className="text-content">Secondary Diagnosis</div>
              <Field
                className="input-field"
                name="secondaryDiagnosis"
                placeholder="Secondary Diagnosis"
              />
              {touched.secondaryDiagnosis && errors.secondaryDiagnosis && (
                <div className="field-error">{errors.secondaryDiagnosis}</div>
              )}
            </label>
            <label className="label1">
              <div className="text-content">Admission Type</div>
              <Field className="input-field" name="admissionType" as="select">
                <option>Admission Type</option>
                <option value="EMERGENCY">EMERGENCY</option>
                <option value="PLAN">PLANNED</option>
              </Field>
              {touched.admissionType && errors.admissionType && (
                <div className="field-error">{errors.admissionType}</div>
              )}
            </label>
            <label className="label1">
              <div className="text-content">Admission Date</div>
              <Field
                className="input-field"
                name="admissionDate"
                placeholder="Admission Date"
                type="date"
                max={new Date().toISOString().split("T")[0]}
                min={
                  new Date(new Date().setDate(new Date().getDate() - 20))
                    .toISOString()
                    .split("T")[0]
                }
              />
              {touched.admissionDate && errors.admissionDate && (
                <div className="field-error">{errors.admissionDate}</div>
              )}
            </label>
            <label className="label1">
              <div className="text-content">FIR/MLC</div>
              <Field
                className="input-field"
                name="fir"
                placeholder="FIR/MLC"
                type="text"
              />
              {touched.fir && errors.fir && (
                <div className="field-error">{errors.fir}</div>
              )}
            </label>
            <label className="label1">
              <div className="text-content">Police Station Name</div>
              <Field
                className="input-field"
                name="policeStationName"
                placeholder="Police Station Name"
                type="text"
              />
              {touched.policeStationName && errors.policeStationName && (
                <div className="field-error">{errors.policeStationName}</div>
              )}
            </label>
            {/* <label className="label1">
              <div className="text-content">Package Cost</div>
              <Field
                className="input-field"
                name="packageCost"
                placeholder="Package Cost"
                type="number"
              />
              {touched.packageCost && errors.packageCost && (
                <div className="field-error">{errors.packageCost}</div>
              )}
            </label> */}
            <label className="label1">
              <div className="text-content">Total Package Cost</div>
              <Field
                className="input-field"
                name="totalPackageCost"
                disabled
                placeholder="Total Package Cost"
                type="text"
                value={values.totalPackageCost}
              />
              {touched.totalPackageCost && errors.totalPackageCost && (
                <div className="field-error">{errors.totalPackageCost}</div>
              )}
            </label>
          </div>
          <div className="button-box">
            {isSubmitting ? (
              <div className="flex justify-center">
                <CircularProgress />
              </div>
            ) : (
              <button
                type="submit"
                disabled={isSubmitting}
                className="fill-btn"
              >
                SAVE AND PROCEED
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddCaseDetails;
